<script>
import { mapGetters, mapActions } from 'vuex'
import http from '~/http'

export default {
  name: 'Notifications',

  data() {
    return {
      timer: null,
      firstLoad: false,
    }
  },

  computed: {
    ...mapGetters(['company', 'isLogged', 'getNotifications', 'getCountUnreadNotifications']),
  },

  watch: {
    company() {
      if (this.firstLoad) {
        this.loadNotifications()
      }
      this.firstLoad = true
    },
  },

  methods: {
    ...mapActions(['setNotifications', 'readAllNotifications', 'addNotification', 'setTotalUnreadNotifications']),

    loadNotifications() {
      http
        .get('notifications/last')
        .then(({ data }) => {
          this.setNotifications(data.data.notifications)
          this.setTotalUnreadNotifications(data.data.total_unread)
        })
        .catch(() => {
          this.$alert.show('Oops', 'Houve um erro ao carregar as notificações.<br>Atualize a página.', 'error')
        })
    },

    readAll() {
      if (this.getCountUnreadNotifications > 0) {
        http
          .put('notifications/read-all')
          .then(({ data }) => {
            this.$alert.show('', data.message, 'success')
            this.readAllNotifications()
          })
          .catch(() => {
            this.$alert.show('', 'Houve um erro ao marcar as notificações como lidas. Tente novamente', 'error')
          })
      }
    },

    listen() {
      window.Echo.private(`Company.${this.company.id}`).notification(notification => {
        this.addNotification(notification)
      })
    },

    tryConnect() {
      if (this.isLogged && this.company.id && window.Pusher.isReady) {
        this.listen()
        return true
      }
      return false
    },

    navigateNotifications() {
      window.$('.dropdown-menu').removeClass('show')
      if (this.$route.name !== 'notifications.index') {
        this.$router.push({ name: 'notifications.index' })
      }
    },

    getTitle(notification) {
      switch (notification.type) {
        case 'BoraDelivery\\Notifications\\Company\\InvoiceCreatedNotification':
          return 'Nova fatura gerada'
        default:
          return 'Notificação'
      }
    },
  },

  mounted() {
    if (this.company.id) {
      this.loadNotifications()
    } else {
      this.firstLoad = true
    }

    this.timer = setInterval(() => {
      if (this.tryConnect()) {
        clearInterval(this.timer)
      }
    }, 2000)

    window.$('#dropdown-notifications').click(function(e) {
      e.stopPropagation()
    })
  },

  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
}
</script>

<template>
  <li class="nav-item dropdown">
    <a
      class="nav-link"
      href="#"
      ref="dropdown"
      role="button"
      aria-haspopup="true"
      aria-expanded="false"
      data-toggle="dropdown"
    >
      <BellIcon :size="18" />

      <span class="badge badge-circle badge-default" v-if="getCountUnreadNotifications !== 0">{{
        getCountUnreadNotifications
      }}</span>
    </a>
    <div id="dropdown-notifications" class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden">
      <!-- Dropdown header -->
      <div class="px-3 py-3">
        <h6 class="text-sm text-muted m-0">
          Você tem
          <strong class="text-primary">{{ getCountUnreadNotifications }}</strong> notificações não lidas.
        </h6>
        <div style="display: flex; justify-content: flex-end">
          <h6 class="text-sm text-muted m-0">
            <a href="#" @click.prevent="readAll">
              <small>marcar todas como lidas</small>
            </a>
          </h6>
        </div>
      </div>
      <!-- List group -->
      <div class="list-group list-group-flush">
        <div class="row align-items-center justify-content-center">
          <div v-if="!getNotifications.length" class="alert">Nenhuma notificação encontrada.</div>
        </div>
        <a
          href="#!"
          class="list-group-item list-group-item-action"
          :class="{ 'bg-gradient-not-read': !notification.read_at }"
          v-for="notification in getNotifications"
          :key="notification.id"
        >
          <div class="row align-items-center">
            <div class="col ml--2">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h4 class="mb-0 text-sm">{{ getTitle(notification) }}</h4>
                </div>
                <div class="text-right text-muted">
                  <small v-tooltip="$options.filters.date(notification.created_at)">{{
                    notification.created_at | date(null, true)
                  }}</small>
                </div>
              </div>
              <p class="text-sm mb-0" v-html="notification.data.text"></p>
            </div>
          </div>
        </a>
      </div>
      <!-- View all -->
      <a
        href="#"
        @click.prevent="navigateNotifications"
        class="dropdown-item text-center text-primary font-weight-bold py-3"
        >Ver todas</a
      >
    </div>
  </li>
</template>

<style scoped>
.bg-gradient-not-read {
  background-color: #f3f3f3;
}
.bg-gradient-not-read:hover {
  background-color: #f6f9fc;
}
.list-group-item {
  padding: 0.5rem 1rem;
}
.badge-circle {
  font-size: 0.5rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0px;
  right: 10px;
  line-height: 6px;
  padding: 2px 4px;
}
</style>
