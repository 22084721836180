import checkPermission from './checkPermission'

const install = (Vue, store) => {
  Object.defineProperty(Vue.prototype, '$acl', {
    get() {
      return checkPermission(this)
    }
  })
}

export default { install }
