<script>
import Echo from 'laravel-echo'
import { mapActions, mapGetters } from 'vuex'
import { apiIndex as apiIndexOrders } from '~/app/orders/services'
import http from '~/http'
import DeliverymenOnline from './DeliverymenOnline'
import NotificationOrders from './orders'

let time

export default {
  name: 'NotificationWebSocket',

  components: { NotificationOrders, DeliverymenOnline },

  data() {
    return {
      loaded: false,
      isConnected: null,
      tries: 0,
    }
  },

  computed: {
    ...mapGetters(['isLogged', 'token', 'company']),
  },

  methods: {
    ...mapActions(['setOrders', 'setOrdersPendents']),

    createConnection() {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        wsHost: process.env.VUE_APP_WEBSOCKETS_HOST,
        wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
        forceTLS: false,
        encrypted: process.env.VUE_APP_WEBSOCKETS_ENCRYPTED === 'true',
        authEndpoint: process.env.VUE_APP_WEBSOCKETS_AUTH_ENDPOINT,
        disableStats: process.env.VUE_APP_WEBSOCKETS_DISABLE_STATS === 'true',
        enabledTransports: ['ws', 'wss'],
        auth: {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'company-id': this.company.id,
          },
        },
      })
      this.loaded = true
    },

    destroyConnection() {
      window.Echo.disconnect()
    },

    retryConnection() {
      if (!this.isConnected) {
        this.tries++
        if (this.tries > 100) {
          clearInterval(time)
          window.swal
            .fire({
              title: 'Erro ao conectar com o servidor de websocket',
              html: 'Não foi possível conectar com o servidor de websocket, tente novamente mais tarde.',
              icon: 'error',
              allowOutsideClick: false,
              showConfirmButton: true,
            })
            .then(() => {
              window.location.reload()
            })
          return
        }

        apiIndexOrders().then(({ data }) => {
          this.setOrders(data.data.orders)
          this.setOrdersPendents(data.data.pendents)
        })

        window.Echo.connector.pusher.connection.disconnect()

        window.Echo.connector.pusher.connection.connect()
      }
    },
  },

  watch: {
    isConnected() {
      http.defaults.headers.common['X-Socket-Id'] = window.Echo.socketId()

      if (!this.isConnected) {
        time = setInterval(() => {
          console.log('Tentando reconectar... Tentativa: ' + this.tries + '/100')
          this.retryConnection()
        }, 15000)
      }
    },
  },

  mounted() {
    if (this.isLogged) {
      this.createConnection()

      window.Echo.connector.pusher.connection.bind('connecting', () => {
        console.log('Conectando... ao servidor de websocket...')
      })

      window.Echo.connector.pusher.connection.bind('connected', () => {
        console.log('Conectado ao servidor de websocket!')
        this.isConnected = true
        this.tries = 0
        clearInterval(time)
      })

      window.Echo.connector.pusher.connection.bind('unavailable', () => {
        console.log('Servidor de websocket indisponível, tentando reconectar...')
        this.isConnected = false
      })

      window.Echo.connector.pusher.connection.bind('failed', () => {
        console.log('Falha ao conectar com o servidor de websocket, tentando reconectar...')
        this.isConnected = false
      })

      window.Echo.connector.pusher.connection.bind('error', () => {
        console.log('Erro ao conectar com o servidor de websocket, tentando reconectar...')
        this.isConnected = false
      })

      window.Echo.connector.pusher.connection.bind('disconnected', () => {
        console.log('Desconectado do servidor de websocket, tentando reconectar...')
        this.isConnected = false
      })
    }

    // Notifications
    const notificationEnabled = !(window.Notification || window.webkitNotifications || navigator.mozNotification)

    if (
      Notification.permission === 'default' &&
      !notificationEnabled &&
      !window.localStorage.getItem('notificationDenied')
    ) {
      window.swal
        .fire({
          title: 'Ativar notificações de pedidos na área de trabalho',
          html: 'Ative as notificações e receba alertas de novos pedidos na área de trabalho.',
          icon: 'info',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Ativar',
          cancelButtonText: 'Cancelar',
          showDenyButton: true,
          denyButtonText: 'Não mostrar novamente',
          denyButtonColor: '#999',
          preConfirm: () => {
            window.swal.fire({
              title: 'Ativar notificações de pedidos',
              html:
                'Para ativar as notificações de pedidos na área de trabalho, clique em Permitir na caixa de dialogo exibida.',
              icon: 'info',
              showConfirmButton: false,
              closeOnConfirm: false,
              allowOutsideClick: false,
            })
            Notification.requestPermission().then(() => {
              window.swal.close()
            })
          },
        })
        .then(result => {
          if (result.isDenied) {
            window.localStorage.setItem('notificationDenied', true)
          }
        })
      window.$('.swal2-container').css('background-color', 'rgba(0, 0, 0, 0.7)')
    }
  },

  destroyed() {
    this.destroyConnection()
  },
}
</script>

<template>
  <span v-if="loaded">
    <NotificationOrders v-if="isLogged" />
    <DeliverymenOnline v-if="isLogged" />
  </span>
</template>
