import * as types from './mutations-types'

export default {
  [types.SET_USER](state, user) {
    state.user = user
  },

  [types.SET_TOKEN](state, token) {
    state.token = token
  },

  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions
  },

  [types.SET_COMPANY](state, company) {
    state.company = company
  },

  [types.SET_COMPANIES](state, companies) {
    state.companies = companies
  },

  [types.UPDATE_STATUS_COMPANY](state, payload) {
    state.company.online = payload
  },

  [types.UPDATE_NEXT_CLOSE_AT](state, payload) {
    state.company.next_close_at = payload
  },

  [types.UPDATE_SETTINGS](state, payload) {
    state.settings = payload
  },
}
