import http from '~/http'
import { localStorageGetItem, localStorageSetItem } from '~/shared/local-storage'

// Define certificates from qz tray
export const init = () => {
  window.qz.security.setCertificatePromise(function(resolve, reject) {
    http
      .get('printers/certificate')
      .then(data => resolve(data.data))
      .catch(reject)
  })

  window.qz.security.setSignaturePromise(function(toSign) {
    return function(resolve, reject) {
      http
        .post('printers/certificate/assigned', { request: toSign })
        .then(data => resolve(data.data))
        .catch(reject)
    }
  })
}

// Get default printer for computer user
export const getPrinterDefault = () => {
  return window.qz.printers.getDefault()
}

// Get all printers from user
export const getPrinters = () => {
  return window.qz.printers.details()
}

export const setPrinterStorageDefault = printer => {
  localStorageSetItem('printer-default', printer)
}

export const getPrinterStorageDefault = () => {
  return localStorageGetItem('printer-default') || null
}

export const setPrinterPaperStorageDefault = size => {
  localStorageSetItem('printer-size', size)
}

export const getPrinterPaperStorageDefault = () => {
  return localStorageGetItem('printer-size') || null
}

export const setPrinterFontSizeStorageDefault = size => {
  localStorageSetItem('printer-font-size', size)
}

export const getPrinterFontSizeStorageDefault = () => {
  return localStorageGetItem('printer-font-size') || null
}

/**
 * Get default printer for kitchen user
 */

export const setPrinterKitchenStorageDefault = printer => {
  localStorageSetItem('printer-kitchen-default', printer)
}

export const getPrinterKitchenStorageDefault = () => {
  return localStorageGetItem('printer-kitchen-default') || null
}

export const setPrinterPaperKitchenStorageDefault = size => {
  localStorageSetItem('printer-kitchen-size', size)
}

export const getPrinterPaperKitchenStorageDefault = () => {
  return localStorageGetItem('printer-kitchen-size') || null
}

export const setPrinterFontSizeKitchenStorageDefault = size => {
  localStorageSetItem('printer-kitchen-font-size', size)
}

export const getPrinterFontSizeKitchenStorageDefault = () => {
  return localStorageGetItem('printer-kitchen-font-size') || null
}

export const disconnect = callback => {
  if (window.qz.websocket.isActive()) {
    window.qz.websocket.disconnect().then(callback)
  }
}
