var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
      'has-danger': _vm.isDanger,
      'form-group': _vm.label && !_vm.formSm,
    }},[(_vm.label)?_c('label',{staticClass:"form-control-label",attrs:{"for":_vm.id}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}),_vm._v(" "),(_vm.info)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.info),expression:"info"}]},[_c('InformationIcon',{staticClass:"text-info",attrs:{"size":16}})],1):_vm._e()]):_vm._e(),(_vm.type === 'money')?_c('money',{ref:"fieldFocus",staticClass:"form-control",class:{
        'form-control-sm': _vm.formSm,
        'is-invalid': _vm.isInvalid,
      },attrs:{"disabled":_vm.disabled,"name":_vm.name,"id":_vm.id,"placeholder":_vm.errorInPlaceholder ? (!!_vm.errors ? _vm.errors : _vm.placeholder) : _vm.placeholder,"type":"tel"},on:{"blur":function($event){return _vm.success()},"input":function($event){return _vm.delayTouch()}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"model"}}):(_vm.type === 'textarea')?_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model),expression:"model",modifiers:{"trim":true}}],ref:_vm.refs,staticClass:"form-control",class:{
        'form-control-sm': _vm.formSm,
        'is-invalid': _vm.isInvalid,
      },attrs:{"maxLength":_vm.maxLength,"disabled":_vm.disabled,"id":_vm.id,"rows":_vm.rows,"name":_vm.name,"placeholder":_vm.errorInPlaceholder ? (!!_vm.errors ? _vm.errors : _vm.placeholder) : _vm.placeholder},domProps:{"value":(_vm.model)},on:{"blur":[function($event){return _vm.success()},function($event){return _vm.$forceUpdate()}],"input":[function($event){if($event.target.composing){ return; }_vm.model=$event.target.value.trim()},function($event){return _vm.delayTouch()}]}}):(!_vm.mask)?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model),expression:"model",modifiers:{"trim":true}}],ref:"fieldFocus",staticClass:"form-control",class:{
        'form-control-sm': _vm.formSm,
        'is-invalid': _vm.isInvalid,
        'is-valid': _vm.isSuccess,
      },attrs:{"maxLength":_vm.maxLength,"min":_vm.min,"max":_vm.max,"id":_vm.id,"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.errorInPlaceholder ? (!!_vm.errors ? _vm.errors : _vm.placeholder) : _vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.model)},on:{"blur":[function($event){return _vm.success()},function($event){return _vm.$forceUpdate()}],"input":[function($event){if($event.target.composing){ return; }_vm.model=$event.target.value.trim()},function($event){return _vm.delayTouch()}]}},'input',_vm.$attrs,false)):(_vm.mask)?_c('the-mask',{ref:"fieldFocus",staticClass:"form-control",class:{
        'form-control-sm': _vm.formSm,
        'is-invalid': _vm.isInvalid,
      },attrs:{"disabled":_vm.disabled,"id":_vm.id,"name":_vm.name,"placeholder":_vm.errorInPlaceholder ? (!!_vm.errors ? _vm.errors : _vm.placeholder) : _vm.placeholder,"mask":_vm.mask,"masked":_vm.masked},on:{"input":function($event){return _vm.delayTouch()}},nativeOn:{"blur":function($event){return _vm.success()}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),(_vm.maxLength)?_c('div',{staticClass:"mt--1 text-gray text-xs position-absolute mr-2",staticStyle:{"right":"0"}},[_c('small',[_vm._v(_vm._s(_vm.model ? _vm.model.length : 0)+"/"+_vm._s(_vm.maxLength))])]):_vm._e(),(!_vm.errorInPlaceholder)?_c('div',_vm._l((Object.keys(_vm.textValidations)),function(error,index){return _c('div',{key:index},[(_vm.v ? _vm.v[error] === false && _vm.v.$dirty : false)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" × "+_vm._s(_vm.textValidations[error])+" ")]):_vm._e()])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }