<script>
import Multiselect from 'vue-multiselect'
let timer = null
let typingTimer

export default {
  name: 'Select',

  components: { Multiselect },

  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: 'Selecione' },
    name: { type: String },
    v: { default: null },
    text_validations: {
      type: Object,
      default: () => ({}),
    },
    trackBy: { default: null },
    labelSelect: { default: null },
    selected: { default: null },
    options: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    openDirection: { type: String, default: '' },
    internalSearch: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: true },
    optionsLimit: { type: Number, default: 1000 },
    limit: { type: Number, default: 999 },
    showNoResults: { type: Boolean, default: true },
    hideSelected: { type: Boolean, default: false },
    query: { type: Function, default: null },
    searchable: { type: Boolean, default: true },
    response: { type: Array, default: () => [] },
    getAllObject: { type: Boolean, default: false },
    formSm: { type: Boolean, default: false },
    customLabel: { type: Function },
  },

  data() {
    return {
      isSuccess: false,
      valueSelected: null,
      model: null,
      loading: false,
      optionsSelect: [],
    }
  },

  computed: {
    isDanger() {
      if (this.v) {
        if (this.v.form[this.name]) {
          if (!this.model && !Object.keys(this.text_validations).find(x => x === 'required')) {
            return false
          }
          return this.v.form[this.name].$dirty && this.v.form[this.name].$invalid
        }
        return false
      }
      return false
    },
  },

  watch: {
    selected() {
      this.isSelected()
    },

    options() {
      this.optionsSelect = this.options
    },

    value() {
      this.model = this.value
      this.success()
    },
  },

  methods: {
    isSelected() {
      if (typeof this.selected !== 'object') {
        this.model = this.selectBySelected(this.selected)
      } else {
        this.model = this.selected
      }
    },

    selectBySelected(item) {
      const val = this.optionsSelect.find(item => item[this.trackBy] === this.selected)
      return val
    },

    success() {
      if (this.v) {
        setTimeout(() => {
          this.isSuccess = this.v.form[this.name] ? !this.v.form[this.name].$invalid : !!this.model
        }, 500)
      }
    },

    delayTouch() {
      if (this.v) {
        if (this.v.form[this.name]) {
          this.v.form[this.name].$reset()
        }
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => (this.v.form[this.name] ? this.v.form[this.name].$touch() : ''), 1000)
      } else {
        this.valid = false
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => {
          this.valid = true
        }, 1000)
      }
    },

    change(ev) {
      if (ev) {
        if (this.getAllObject) {
          this.$emit('input', ev)
        } else {
          if (this.trackBy) {
            this.$emit('input', ev[this.trackBy])
          } else {
            this.$emit('input', ev)
          }
        }
      } else {
        this.$emit('input', null)
      }
    },

    getAttributesData(response) {
      let items = response
      this.response.forEach(item => {
        items = items[item]
      })

      return items
    },

    asyncFind(query) {
      if (!this.query) return
      const q = query.trim()

      if (this.query && q.length >= 2) {
        this.loading = true
        clearTimeout(timer)

        timer = setTimeout(() => {
          this.query(q)
            .then(response => {
              this.optionsSelect = this.getAttributesData(response)
            })
            .finally(() => {
              this.loading = false
            })
        }, 500)
      } else {
        this.optionsSelect = []
      }
    },
  },

  mounted() {
    this.optionsSelect = this.options
    this.model = this.selected
    this.isSelected()
  },
}
</script>

<template>
  <div>
    <div :class="{ invalid: isDanger, 'form-group': label && !formSm, 'shas-success': isSuccess }">
      <label class="form-control-label" :for="name" v-if="label">{{ label }}</label>
      <multiselect
        :ref="`ref_${name}`"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': v && v.form[name] ? v.form[name].$dirty && v.form[name].$invalid : false,
        }"
        v-model="model"
        :id="name"
        :name="name"
        :multiple="multiple"
        :clear-on-select="clearOnSelect"
        :placeholder="placeholder"
        :label="labelSelect"
        :track-by="trackBy"
        @input="change"
        :options="optionsSelect"
        :limit-text="count => `e ${count} mais`"
        :loading="loading"
        :disabled="disabled"
        :open-direction="openDirection"
        :searchable="searchable"
        :internal-search="internalSearch"
        :close-on-select="closeOnSelect"
        :options-limit="optionsLimit"
        :limit="limit"
        :show-no-results="showNoResults"
        :hide-selected="hideSelected"
        @search-change="asyncFind"
        :custom-label="customLabel"
        select-label="Selecionar"
        selected-label="Selecionado"
        deselect-label="Remover"
      >
        <span slot="noResult">Nenhum resultado</span>
        <span slot="noOptions">Digite 2 caracteres para pesquisar</span>
      </multiselect>

      <div :key="index" v-for="(error, index) in Object.keys(text_validations)">
        <div style="display: block;" class="invalid-feedback" v-if="v ? isDanger && !v.form[name][error] : false">
          &times; {{ text_validations[error] }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.multiselect {
  min-width: 100px;
}

.multiselect__tags {
  height: calc(1.5em + 1.25rem + 2px) !important;
}
.multiselect__option--highlight {
  background: rgb(255, 159, 90) !important;
}
.multiselect__option--highlight:after {
  background: rgb(255, 159, 90) !important;
}
.multiselect__placeholder {
  padding-left: 5px;
  margin-bottom: 8px;
  width: 100%;
  font-size: 16px;
  min-height: 25px;
  color: #666 !important;
}
.multiselect__input,
.multiselect__single {
  min-height: 30px !important;
  line-height: 30px !important;
  color: #333 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiselect__spinner:before {
  border-top-color: rgb(255, 159, 90) !important;
  margin: -4px 0 0 -8px !important;
}

.multiselect__spinner:after {
  border-top-color: rgb(255, 159, 90) !important;
  margin: -4px 0 0 -8px !important;
}

.invalid .typo__label {
  color: #f5365c;
}

.invalid .multiselect__tags {
  border-color: #f5365c !important;
}

.invalid .multiselect__placeholder {
  color: #f5365c !important;
}
</style>
