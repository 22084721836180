<script>
import { mapGetters } from 'vuex'
import TopBar from './app/TopBar'
import Navbar from './app/Navbar'

export default {
  name: 'AppLayout',

  components: { TopBar, Navbar },

  computed: {
    ...mapGetters(['isLogged'])
  }
}
</script>

<template>
  <div v-if="isLogged">
    <Navbar />

    <div class="main-content">
      <TopBar />

      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
