<script>
import { v4 as uuid } from 'uuid'
let typingTimer

export default {
  name: 'Input',

  props: {
    maxLength: { default: null },
    editable: { default: false },
    clearable: { default: false },
    rangeHour: { default: '' },
    notAfter: { default: '' },
    notBefore: { default: '' },
    disabled: { default: false },
    refs: { default: '' },
    label: { default: '' },
    placeholder: { default: '' },
    errorInPlaceholder: { type: Boolean, default: false },
    type: { default: 'text' },
    mask: { default: '' },
    masked: { type: Boolean, default: false },
    name: { default: null, required: true, type: String },
    v: { default: '' },
    textValidations: { default: '' },
    value: { default: '' },
    focus: { default: '' },
    selectAll: { default: false },
    min: { default: null },
    max: { default: null },
    formSm: { default: false },
    rows: { type: Number, default: 10 },
    info: { type: String, default: '' },
    each: { type: Boolean, default: false },
  },
  data() {
    return {
      model: '',
      valid: false,
      isSuccess: false,
      validTouchMap: {},
      id: uuid(),
    }
  },
  watch: {
    model() {
      const { model } = this
      this.$emit('input', model)
    },

    value() {
      this.model = this.value
    },
  },
  computed: {
    vIsEmpty() {
      return Object.keys(this.v).length === 0
    },

    isInvalid() {
      if (this.vIsEmpty) {
        return false
      }

      return this.isDanger && this.v.$dirty && this.v.$invalid
    },

    errors() {
      if (!this.vIsEmpty) {
        if (this.v.$dirty && this.v.$invalid) {
          const errors = []
          Object.keys(this.textValidations).forEach(error => {
            errors.push(this.textValidations[error])
          })
          return errors[0]
        }
      }
      return ''
    },

    isDanger() {
      if (!this.vIsEmpty) {
        return this.v.$dirty && this.v.$invalid
      }
      return false
    },
  },
  methods: {
    success() {
      this.$emit('blur')
      if (this.v) {
        setTimeout(() => {
          this.isSuccess = !this.vIsEmpty && this.v ? !this.v.$invalid : !!this.model
        }, 500)
      }
    },

    delayTouch() {
      if (!this.vIsEmpty) {
        this.v.$reset()
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => this.v.$touch(), 1000)
      } else {
        this.valid = false
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => {
          this.valid = true
        }, 1000)
      }
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs.fieldFocus.focus()
      this.$nextTick(() => {
        if (this.selectAll) {
          this.$refs.fieldFocus.select()
        }
      })
    }
    this.model = this.value
  },
}
</script>

<template>
  <div>
    <div
      :class="{
        'has-danger': isDanger,
        'form-group': label && !formSm,
      }"
    >
      <label class="form-control-label" v-if="label" :for="id">
        <span v-html="label"></span>
        &nbsp;
        <span v-if="info" v-tooltip="info">
          <InformationIcon :size="16" class="text-info" />
        </span>
      </label>

      <money
        :disabled="disabled"
        @blur="success()"
        v-model.trim="model"
        :name="name"
        :id="id"
        ref="fieldFocus"
        @input="delayTouch()"
        v-if="type === 'money'"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': isInvalid,
        }"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
        type="tel"
        class="form-control"
      />

      <textarea
        :maxLength="maxLength"
        :disabled="disabled"
        @blur="success()"
        :id="id"
        :rows="rows"
        class="form-control"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': isInvalid,
        }"
        v-model.trim="model"
        :name="name"
        :ref="refs"
        @input="delayTouch()"
        v-else-if="type === 'textarea'"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
      ></textarea>

      <input
        :maxLength="maxLength"
        :min="min"
        :max="max"
        :id="id"
        v-bind="$attrs"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': isInvalid,
          'is-valid': isSuccess,
        }"
        :disabled="disabled"
        @blur="success()"
        v-model.trim="model"
        :name="name"
        ref="fieldFocus"
        @input="delayTouch()"
        v-else-if="!mask"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
        :type="type"
        class="form-control"
      />

      <the-mask
        :disabled="disabled"
        @blur.native="success()"
        :id="id"
        v-else-if="mask"
        ref="fieldFocus"
        class="form-control"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': isInvalid,
        }"
        :name="name"
        @input="delayTouch()"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
        :mask="mask"
        :masked="masked"
        v-model="model"
      />
      <div class="mt--1 text-gray text-xs position-absolute mr-2" style="right: 0" v-if="maxLength">
        <small>{{ model ? model.length : 0 }}/{{ maxLength }}</small>
      </div>

      <div v-if="!errorInPlaceholder">
        <div :key="index" v-for="(error, index) in Object.keys(textValidations)">
          <div class="invalid-feedback" v-if="v ? v[error] === false && v.$dirty : false">
            &times; {{ textValidations[error] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid-feedback {
  display: block;
}
</style>
