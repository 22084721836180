const Index = () => import('./components/index')

export default [
  {
    path: '/bairros',
    name: 'neighborhoods.index',
    meta: {
      title: 'Bairros',
    },
    component: Index,
  },
]
