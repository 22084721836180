<script>
export default {
  name: 'Loading',
  props: {
    loading: { type: Boolean, default: false },
    size: { type: String, default: '' },
    type: { type: String, default: 'border' },
    color: { type: String, default: 'primary' }
  }
}
</script>

<template>
  <div class="col-md-12" v-if="loading" style="text-align: center;">
    <div
      :class="[
        {
          'spinner-grow': type === 'grow',
          'spinner-border': type === 'border',
          'spinner-grow-lg': type === 'grow' && size === 'lg',
          'spinner-border-lg': type === 'border' && size === 'lg'
        },
        `text-${color}`
      ]"
      role="status"
    >
      <span class="sr-only">Carregando...</span>
    </div>
  </div>
</template>
