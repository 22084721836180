export const getCountTotalOrders = state => {
  return state.orders.length
}

export const playSoundBell = state => {
  return state.orders.filter(or => or.status === 'opened').length > 0
}

export const getCountTotalOpenedOrders = state => {
  return state.orders.filter(or => or.status === 'opened').length
}

export const getCountTotalCanceledOrders = state => {
  return state.orders.filter(or => or.status === 'canceled').length
}

export const getOrder = state => {
  return id => state.orders.find(order => order.id === id)
}

// Get array orders from status

export const getOrdersOpened = state => {
  return state.orders.filter(or => or.status === 'opened').map(order => ({ ...order, opened: !!order.opened }))
}

export const getOrdersConfirmed = state => {
  return state.orders.filter(or => or.status === 'confirmed')
}

export const getOrdersProduction = state => {
  return state.orders.filter(or => or.status === 'production')
}

export const getOrdersCanceled = state => {
  return state.orders.filter(or => or.status === 'canceled')
}

export const getOrdersSent = state => {
  return state.orders.filter(or => or.status === 'sent' || or.status === 'delivery_arrived')
}

export const getOrdersReadyDelivery = state => {
  return state.orders.filter(or => or.status === 'ready_delivery')
}

export const getOrdersCaughtDelivery = state => {
  return state.orders.filter(
    or => or.status === 'order_caught' || or.status === 'order_accepted' || or.status === 'deliveryman_arrived_company',
  )
}

export const getOrdersReadyForPickup = state => {
  return state.orders.filter(or => or.status === 'ready_for_pickup')
}

export const getOrdersCompleted = state => {
  return state.orders.filter(or => or.status === 'completed')
}
