import http from '~/http'

export const apiIndex = (startDate, endDate) => {
  return http.get('orders', { params: { startDate, endDate } })
}

export const apiShow = id => {
  return http.get(`orders/${id}`)
}

export const apiChangeStatus = (id, data) => {
  return http.put(`orders/${id}/status`, data)
}

export const apiCancelOrder = (id, reason) => {
  return http.post(`orders/${id}/cancel`, { reason })
}
