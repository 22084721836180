const index = () => import('./components/index.vue')

export default [
  {
    path: '/avaliacoes',
    name: 'evaluations.index',
    meta: {
      title: 'Avaliações'
    },
    component: index
  }
]
