import * as types from './mutations-types'

export default {
  [types.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications
  },

  [types.SET_TOTAL_UNREAD_NOTIFICATIONS](state, total) {
    state.unread = total
  },

  [types.ADD_NOTIFICATION](state, notification) {
    if (state.notifications >= 8) {
      state.notifications.pop()
    }

    state.unread = state.unread + 1

    state.notifications.unshift(notification)
  },

  [types.READ_NOTIFICATION](state, notification) {
    state.unread = state.unread - 1
    state.notifications = state.notifications.map(item => {
      if (notification.id === item.id) {
        return notification
      }
      return item
    })
  },

  [types.READ_ALL_NOTIFICATIONS](state) {
    state.unread = 0
    state.notifications = state.notifications.map(item => {
      return { ...item, read_at: new Date() }
    })
  },

  [types.DESTROY_NOTIFICATION](state, notification) {
    state.notifications = state.notifications.filter(item => item.id !== notification.id)

    if (!notification.read_at) {
      state.unread = state.unread - 1
    }
  },
}
