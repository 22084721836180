const index = () => import('./components/index.vue')
const show = () => import('./components/show')

export default [
  {
    path: '/faturas',
    name: 'invoices.index',
    meta: {
      title: 'Faturas',
    },
    component: index,
  },
  {
    path: '/faturas/:id',
    name: 'invoices.show',
    meta: {
      title: 'Ver fatura',
    },
    component: show,
  },
]
