window.$(document).ready(function() {
  const body = window.$('body')
  const sidenavToggler = window.$('.sidenav-toggler')

  function appendBackdropBody() {
    body.append(
      '<div class="backdrop d-xl-none" data-action="sidenav-unpin" data-target=' +
        window.$('#sidenav-main').data('target') +
        ' />',
    )

    window.$('.backdrop').click(function() {
      removeBackdropBody()
    })
  }

  function removeBackdropBody() {
    const width = window.innerWidth
    if (body.hasClass('g-sidenav-pinned') && width < 1200) {
      body.removeClass('g-sidenav-pinned')
    }
    body.find('.backdrop').remove()
  }

  function saveBodyClassStorage(className) {
    localStorage.setItem('menu-pinned', JSON.stringify(className))
  }

  function getBodyClassStorage() {
    return JSON.parse(localStorage.getItem('menu-pinned'))
  }

  window.$('#sidenav-main').hover(
    function() {
      body.addClass('g-sidenav-show')
    },
    function() {
      const hasClassBody = body.hasClass('g-sidenav-pinned g-sidenav-show')
      if (!hasClassBody) {
        body.removeClass('g-sidenav-show')
      }
    },
  )

  window.$('ul.navbar-nav li.nav-item a.nav-link').click(function() {
    removeBackdropBody()
  })

  sidenavToggler.click(function() {
    const width = window.innerWidth

    const hasClassBody = body.hasClass('g-sidenav-pinned g-sidenav-show')
    if (hasClassBody) {
      sidenavToggler.addClass('active')
      body.removeClass('g-sidenav-pinned')
      body.removeClass('g-sidenav-show')
      body.addClass('g-sidenav-hidden')
      saveBodyClassStorage('g-sidenav-hidden')
    } else {
      sidenavToggler.removeClass('active')
      body.addClass('g-sidenav-pinned')
      body.addClass('g-sidenav-show')
      body.removeClass('g-sidenav-hidden')
      saveBodyClassStorage('g-sidenav-pinned g-sidenav-show')
    }

    if (width < 1200) {
      appendBackdropBody()
    }
  })

  function showMenuOrNo() {
    const width = window.innerWidth

    if (width >= 1200) {
      const storageClassBody = getBodyClassStorage()
      body.addClass(storageClassBody || 'g-sidenav-pinned g-sidenav-show')
      if (storageClassBody === 'g-sidenav-hidden') {
        sidenavToggler.addClass('active')
      }
    } else {
      sidenavToggler.removeClass('active')
      body.removeClass('g-sidenav-pinned')
    }
  }

  showMenuOrNo()

  window.addEventListener('resize', showMenuOrNo, false)
})
