/* eslint-disable no-undef */

import './css/nprogress.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import './css/app.css'
import './css/styles.css'

window.$ = window.jQuery = require('jquery')

require('bootstrap')
require('./js/app')
require('./js/blitzmap')
window.qz = require('./js/qz-tray')

window.swal = require('sweetalert2')
window.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
window.Pusher = require('pusher-js')

window.isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}
