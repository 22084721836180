<script>
import { mapGetters, mapActions } from 'vuex'
import http from '~/http'
import errorsFormat from '../../shared/errors-format'

export default {
  name: 'OnlineOffline',

  data() {
    return {
      isMobile: window.isMobile(),
      close_at: null,
      open_24: null,
    }
  },

  computed: {
    ...mapGetters(['company', 'settings', 'deliveryByCompany', 'totalDeliverymenOnline']),

    online() {
      return this.company.online
    },

    nextClose() {
      return this.company.next_close_at
    },
  },

  methods: {
    ...mapActions(['updateStatusCompany', 'updateNextCloseAtCompany']),

    toggleOnline() {
      http
        .post('company/toggle-online')
        .then(({ data }) => {
          if (data.data.online) {
            this.$alert.show('', 'Sua empresa agora está <strong>ONLINE</strong>.', 'success')
          } else {
            this.$alert.show('', 'Sua empresa agora está <strong>OFFLINE</strong>.', 'success')
          }

          this.updateStatusCompany(data.data.online)
          this.updateNextCloseAtCompany(data.data.next_close_at)
        })
        .catch(error => {
          const { data } = error.response
          this.$alert.show('Ops', errorsFormat(error), 'error')
          this.updateStatusCompany(data.data.online)
        })
    },
  },

  mounted() {
    window.$('#dropdown-online').click(function(e) {
      e.stopPropagation()
    })
  },
}
</script>

<template>
  <li class="nav-item dropdown">
    <a
      :class="online ? 'aonline' : 'aoffline'"
      class="nav-link"
      href="#"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :title="online ? 'Ficar indisponível' : 'Ficar disponível'"
    >
      <CircleIcon v-if="isMobile" :size="18" :title="online ? 'Ficar offline' : 'Ficar online'" />
      <span v-if="!isMobile" id="electron-company-is-online" class="mb-0 text-sm font-weight-bold">
        {{ online ? ' ONLINE' : ' OFFLINE' }}
      </span>
      <ChevronDownIcon />
    </a>
    <div class="dropdown-menu dropdown-menu-right" id="dropdown-online">
      <div class="p-2" style="width: 300px">
        <div class="mb-1">
          <small class="text" v-if="!deliveryByCompany"> Entregadores online: {{ totalDeliverymenOnline }} </small>
        </div>

        <div v-if="nextClose && online" class="mb-1">
          <span class="small">
            <i>Fechamento automático: {{ nextClose | date }}</i>
          </span>
        </div>

        <div v-if="!online">
          <div class="small mb-2">
            Sua empresa será fechada automaticamente conforme os horários definidos em "Horários de Funcionamento".
          </div>
        </div>

        <bora-button
          type="button"
          @click.prevent="toggleOnline"
          class="btn btn-primary mt-1 w-100"
          :class="online ? 'btn-danger' : 'btn-success'"
        >
          <CircleIcon :size="18" title /> FICAR {{ online ? 'OFFLINE' : 'ONLINE' }}</bora-button
        >
      </div>
    </div>
  </li>
</template>

<style scoped>
li .aoffline {
  background: #f2483e;
  border-radius: 8px;
}
li .aonline {
  background: #22d78d;
  border-radius: 8px;
}
button.offline > span {
  color: #f2483e;
}
button.online > span {
  color: #22d78d;
}
</style>
