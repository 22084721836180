<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeliverymenOnline',

  data() {
    return {
      interval: null,
    }
  },

  computed: {
    ...mapGetters(['company', 'isLogged']),
  },

  methods: {
    ...mapActions(['addDeliverymen', 'removeDeliverymen']),

    listen() {
      window.Echo.private(`City.${this.company.city_id}`).listen('.deliveryman_toggle_online', data => {
        if (data.online) {
          this.addDeliverymen()
        } else {
          this.removeDeliverymen()
        }
      })
    },

    tryConnect() {
      if (this.isLogged && this.company.id && window.Pusher.isReady) {
        this.listen()
        return true
      }
      return false
    },
  },

  mounted() {
    this.interval = setInterval(() => {
      if (this.tryConnect()) {
        clearInterval(this.interval)
      }
    }, 2000)
  },

  destroyed() {
    clearInterval(this.interval)
  },
}
</script>

<template>
  <span></span>
</template>
