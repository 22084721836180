import { parseISO, format, addMinutes, formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export default (value, type = 'dateFormatted', deliveryTime = null) => {
  if (!value) {
    return ''
  }

  const dateISO = parseISO(value)

  if (type === 'dateHuman') {
    return format(dateISO, "dd 'de' MMMM 'de' Y", {
      locale: ptBR,
    })
  }

  if (type === 'dateHourHuman') {
    return format(dateISO, "dd 'de' MMMM 'de' Y 'às' H:mm", {
      locale: ptBR,
    })
  }

  if (type === 'dateFormatted') {
    return format(dateISO, 'dd/MM/Y', { locale: ptBR })
  }

  if (type === 'dateHourFormatted') {
    return format(dateISO, "dd/MM/Y 'às' H:mm", {
      locale: ptBR,
    })
  }

  if (type === 'hourFormatted') {
    return format(dateISO, 'H:mm', { locale: ptBR })
  }

  if (type === 'hourSecondsFormatted') {
    return format(dateISO, 'H:mm:ss', { locale: ptBR })
  }

  if (type === 'distanceNow') {
    return formatDistanceToNow(dateISO, {
      locale: ptBR,
      includeSeconds: true,
      addSuffix: true,
    })
  }

  if (type === 'sumDeliveryTime') {
    if (!deliveryTime) return 'null'

    const splited = deliveryTime.split('/')
    const min = parseFloat(splited[0])
    const max = parseFloat(splited[1])
    if (max) {
      return `${format(addMinutes(dateISO, min), 'H:mm', {
        locale: ptBR,
      })} - ${format(addMinutes(dateISO, max), 'H:mm', { locale: ptBR })}`
    } else {
      return format(addMinutes(dateISO, min), 'H:mm', { locale: ptBR })
    }
  }
}
