<script>
export default {
  name: 'ErrorsDiv',

  props: {
    v: { type: Object, required: true },
    textValidations: { type: Object, required: true },
  },

  computed: {
    vIsEmpty() {
      return Object.keys(this.v).length === 0
    },
  },
}
</script>

<template>
  <div class="was-validated" v-if="!vIsEmpty && v.$dirty && v.$invalid">
    <div class="invalid-feedback" :key="index" v-for="(error, index) in Object.keys(textValidations)">
      <span v-if="!vIsEmpty && v[error] === false && v['$dirty']"> &times; {{ textValidations[error] }} </span>
    </div>
  </div>
</template>

<style scoped>
.invalid-feedback {
  text-align: left;
  display: block;
}
</style>
