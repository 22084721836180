<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'

export default {
  name: 'Month',

  components: { DatePicker },

  props: {
    type: { type: String },
    editable: { default: false },
    clearable: { default: false },
    rangeHour: { default: '' },
    notAfter: { default: '' },
    notBefore: { default: '' },
    disabled: { default: false },
    label: { default: '' },
    placeholder: { default: '' },
    format: { type: String, default: 'DD/MM/YYYY' },
    valueType: { type: String, default: 'YYYY-MM' },
    value: { type: String },
    disabledDate: { type: Function, default: () => {} },
  },

  data() {
    return {
      model: null,
    }
  },

  watch: {
    model() {
      this.$emit('input', this.model)
    },

    value() {
      this.model = this.value
    },
  },

  mounted() {
    this.model = this.value
  },
}
</script>

<template>
  <div>
    <label class="form-control-label">{{ label }}</label>
    &nbsp;
    <DatePicker
      :disabled="disabled"
      :editable="editable"
      :not-after="notAfter"
      :not-before="notBefore"
      :clearable="clearable"
      :confirm="true"
      :placeholder="placeholder"
      ref="fieldFocus"
      :type="type"
      v-model="model"
      :time-picker-options="!rangeHour ? {} : rangeHour"
      :format="format"
      lang="pt-br"
      input-class="form-control"
      :value-type="valueType"
      :disabled-date="disabledDate"
    />
  </div>
</template>

<style src="./styles.css"></style>
