const Index = () => import('./components/index')

export default [
  {
    path: '/configuracoes',
    name: 'settings',
    meta: {
      title: 'Configurações'
    },
    component: Index
  }
]
