const Index = () => import('./components/index')
const Show = () => import('./components/list/show')

export default [
  {
    path: '/pedidos',
    name: 'orders',
    meta: {
      title: 'Pedidos'
    },
    component: Index,
    children: [
      {
        path: '/pedidos/:id',
        name: 'orders.show',
        component: Show
      }
    ]
  }
]
