import * as types from './mutations-types'

export const setNotifications = (context, payload) => {
  context.commit(types.SET_NOTIFICATIONS, payload)
}

export const setTotalUnreadNotifications = (context, payload) => {
  context.commit(types.SET_TOTAL_UNREAD_NOTIFICATIONS, payload)
}

export const addNotification = (context, payload) => {
  context.commit(types.ADD_NOTIFICATION, payload)
}

export const readNotification = (context, payload) => {
  context.commit(types.READ_NOTIFICATION, payload)
}

export const readAllNotifications = context => {
  context.commit(types.READ_ALL_NOTIFICATIONS)
}

export const destroyNotification = (context, payload) => {
  context.commit(types.DESTROY_NOTIFICATION, payload)
}
