const errorsApiFormat = (error, status) => {
  let errors = ''

  if (error.response && error.response.data && error.response.data.errors) {
    const errorsResponse = error.response.data.errors
    Object.keys(errorsResponse).forEach(error => {
      errors += `${errorsResponse[error]}<br>`
    })
    return errors
  }

  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message
  }

  if (status === 404) {
    return 'Nao conseguimos encontrar o que você está procurando.'
  }

  return 'Houve um erro na requisição, tente novamente.'
}

export default errorsApiFormat
