const Index = () => import('./components/index')

export default [
  {
    path: '/financeiro',
    name: 'finances',
    meta: {
      title: 'Financeiro'
    },
    component: Index
  }
]
