const Dashboard = () => import('./components/dashboard.vue')

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Dashboard'
    },
    component: Dashboard
  }
]
