<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

export default {
  name: 'DateTime',

  components: { VueCtkDateTimePicker },

  props: {
    label: { type: String, default: '' },
    noLabel: { type: Boolean, default: false },
    onlyDate: { type: Boolean, default: null },
    onlyTime: { type: Boolean, default: true },
    format: { type: String, default: 'YYYY-MM-DD' },
    formatted: { type: String, default: 'DD/MM/YYYY' },
    minuteInterval: { type: Number, default: 10 }
  },

  data() {
    return {
      model: null
    }
  },

  watch: {
    model(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<template>
  <VueCtkDateTimePicker
    color="#fb6340"
    locale="pt-BR"
    :formatted="formatted"
    :format="'MM-YYYY'"
    v-model="model"
    button-now-translation="Agora"
    :label="label"
  />
</template>
