import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

import { store as auth } from '~/app/auth/login'
import { store as orders } from '~/app/orders'
import { store as notifications } from '~/app/notifications'
import { store as menu } from '~/app/menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    orders,
    notifications,
    menu,
  },
})
