<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

export default {
  name: 'DateTime',

  components: { VueCtkDateTimePicker },

  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    value: { default: null },
    props: { default: null },
    noLabel: { type: Boolean, default: false },
    format: { type: String, default: 'YYYY-MM-DD' },
    formatted: { type: String, default: 'DD/MM/YYYY' },
    minuteInterval: { type: Number, default: 10 }
  },

  data() {
    return {
      model: null
    }
  },

  watch: {
    value(val) {
      this.model = val
    },

    model() {
      if (!this.model) {
        this.$emit('input', this.model)
      }
    }
  },

  methods: {
    validate() {
      if (!this.model) {
        this.$emit('input', this.model)
        return
      }
      if (this.model.start && this.model.end) {
        this.$emit('input', this.model)
        return
      }
      if (!this.model.end && !this.model.start) {
        this.$emit('input', this.model)
        return
      }
      if (!this.model.end) {
        this.model = { ...this.model, end: this.model.start }
        this.$emit('input', this.model)
      }
    }
  },

  mounted() {
    this.model = this.value
  }
}
</script>

<template>
  <div>
    <label class="form-control-label" v-if="label">{{ label }}</label>
    <VueCtkDateTimePicker
      :range="true"
      color="#fb6340"
      locale="pt-BR"
      :formatted="formatted"
      :format="format"
      v-model="model"
      button-now-translation="Agora"
      :no-label="true"
      :label="placeholder"
      @is-hidden="validate"
      :custom-shortcuts="[
      {
        key: 'thisday',
        label: 'Hoje',
        value: 'day'
      },
      {
        key: 'lastday',
        label: 'Ontem',
        value: '-day'
      },
      {
        key: 'thisWeek',
        label: 'Essa semana',
        value: 'isoWeek'
      },
      {
        key: 'lastWeek',
        label: 'Semana passada',
        value: '-isoWeek'
      },
      { key: 'last7Days', label: 'Últimos 7 dias', value: 7 },
      {
        key: 'last30Days',
        label: 'Últimos 30 dias',
        value: 30
      },
      { key: 'thisMonth', label: 'Esse mês', value: 'month' },
      {
        key: 'lastMonth',
        label: 'Mês passado',
        value: '-month'
      }
    ]"
      v-bind="props"
    />
  </div>
</template>

<style>
.datepicker-container > .shortcuts-container {
  width: 170px !important;
  min-width: 170px !important;
}

.datetimepicker .datepicker {
  width: 500px !important;
  min-width: 500px !important;
}

.date-time-picker .time-picker-overlay {
  z-index: 1051 !important;
}

.datetimepicker {
  z-index: 1052 !important;
}

.datepicker-container .datepicker-controls .datepicker-button svg {
  width: 24px !important;
  height: 24px !important;
}
</style>
