import Vue from 'vue'
import App from './App.vue'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import VTooltip from 'v-tooltip'
import Validations from 'vuelidate'
import VModal from 'vue-js-modal'
import Transitions from 'vue2-transitions'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import router from './router'
import store from './store'
import Alert from '~/shared/alert'
import ACL from './shared/acl'
import filters from './shared/filters'

import './components'
import './assets/icons'

/**
 * Import assets
 */
import './assets'

// Layouts
import Clean from './layout/Clean.vue'
import Default from './layout/Default.vue'
Vue.component('clean-layout', Clean)
Vue.component('app-layout', Default)

require('./router/interceptors')

Vue.config.productionTip = false

/**
 * Uses
 */

Vue.use(ACL)

Vue.use(Validations)

Vue.use(VueTheMask)

Vue.use(VTooltip)

/**
 * register directive v-money and component <money>
 */
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: true,
})

Vue.use(Transitions)

Vue.use(VModal)

Vue.use(Alert)

Vue.use(PerfectScrollbar, {
  options: {
    wheelPropagation: false,
  },
})

new Vue({
  router,
  store,
  filters,
  render: h => h(App),
}).$mount('#app')
