export const getMinPriceProduct = (groups, priceProduct) => {
  return (
    Number(
      groups
        .map(g => {
          if (g.required) {
            if (g.min_items === 1) {
              return Math.min(...g.items.map(i => i.price))
            } else {
              return Math.min(...g.items.map(i => i.price)) * g.min_items
            }
          } else {
            return 0
          }
        })
        .reduce((a, b) => a + b, 0),
    ) + Number(priceProduct)
  )
}

export const discountInMoney = (discount, discountType, minPriceProduct) => {
  if (discountType === 'money') {
    return Number(discount)
  }
  const discountPercentage = Number(discount) / 100

  return Number(minPriceProduct) * discountPercentage
}

export const discountInPercentage = (discount, discountType, minPriceProduct) => {
  if (discountType === 'percentage') {
    return discount
  }
  const percentage = Number(minPriceProduct) - (Number(discount) * 100) / minPriceProduct

  if (percentage === -Infinity || percentage === Infinity || isNaN(percentage)) {
    return 0
  }

  return Number(minPriceProduct - percentage).toFixed(2)
}

export const getDiscountInMoneyByCoupon = (coupon, price) => {
  return discountInMoney(coupon.discount, coupon.type, price)
}
