const Index = () => import('./components/index')

export default [
  {
    path: '/entregas-raio',
    name: 'radius.index',
    meta: {
      title: 'Entregas por raio',
    },
    component: Index,
  },
]
