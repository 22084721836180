import { isEmpty } from 'lodash'

export const isLogged = state => {
  return !isEmpty(state.token)
}

export const token = state => {
  return state.token
}

export const user = state => {
  return state.user
}

export const permissions = state => {
  return state.permissions
}

export const company = state => {
  return state.company
}

export const companies = state => {
  return state.companies
}

export const settings = state => {
  return state.settings
}

export const deliveryByCompany = state => {
  return state.company.delivery_by === 'company'
}

export const companySetDeliveryValues = state => {
  return (
    (state.company.delivery_by === 'company' || state.company.delivery_by === 'app_company') && state.company.use_price
  )
}

export const totalCompanies = state => {
  return state.companies.length
}

export const isSigning = state => {
  if (state.company) {
    return !Object.keys(state.company).length > 0
  }

  if (state.user) {
    if (state.user.is_admin > 0) {
      return false
    }

    if (state.user.companies && state.user.companies.length > 0) {
      return true
    }
  }

  return true
}

export const companyPrinters = state => {
  if (state.company) {
    return state.company.printers
  }

  return []
}

export const companyPrinterDefault = state => {
  if (state.company) {
    return state.company.printers.filter(printer => printer.default === true)[0]
  }
  return {}
}

export const abilityNeighborhoods = state => {
  return state.company.calc_delivery === 'neighborhood'
}
