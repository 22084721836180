import Vue from 'vue'
import Button from './Button'
import Loading from './Loading'
import Input from './Input/index.vue'
import SingleFile from './Input/File.vue'
import Select from './Select'
import DateTime from './Input/DateTime.vue'
import DateTimeRange from './Input/DateTimeRange.vue'
import Timeago from '~/components/Timeago'
import MonthPicker from '~/components/Input/MonthPicker'
import Pagination from '~/components/Pagination'
import StatusInvoice from '~/components/StatusInvoice'
import Modal from '~/components/Modal'
import File from './File.vue'
import NewInput from './NewInput.vue'
import ErrorsDiv from './ErrorsDiv.vue'

Vue.component('BoraSelect', Select)
Vue.component('BoraButton', Button)
Vue.component('BoraLoading', Loading)
Vue.component('BoraInput', Input)
Vue.component('BoraSingleFile', SingleFile)
Vue.component('BoraDateTimePicker', DateTime)
Vue.component('BoraDateTimeRange', DateTimeRange)
Vue.component('timeago', Timeago)
Vue.component('BoraMonthPicker', MonthPicker)
Vue.component('BoraPagination', Pagination)
Vue.component('BoraStatusInvoice', StatusInvoice)
Vue.component('BoraModal', Modal)
Vue.component('BoraFile', File)
Vue.component('BoraNewInput', NewInput)
Vue.component('ErrorsDiv', ErrorsDiv)
