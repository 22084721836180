<script>
export default {
  name: 'Modal',

  props: {
    title: { type: String, required: true },
    loading: { type: Boolean, default: false },
    showClose: { type: Boolean, default: true },
  },

  methods: {
    close() {
      this.$parent.$emit('close')
    },
  },
}
</script>

<template>
  <div class="scroll-modal">
    <div class="modal-content" v-if="!loading">
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">{{ title }}</h6>
        <button type="button" class="close" @click.prevent="close" v-if="showClose">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <slot></slot>
    </div>

    <div class="col-md-12 p-5" v-if="loading">
      <bora-loading :loading="loading" />
    </div>
  </div>
</template>

<style scoped>
.scroll-modal {
  overflow-y: auto !important;
  height: 100% !important;
}
</style>
