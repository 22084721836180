<script>
import { mapGetters } from 'vuex'
import NotificationCompany from '~/components/Notifications/notifications'
import CompanySelector from './CompanySelector'
import InstallPWA from './InstallPWA'
import MenuUser from './MenuUser'
import BoraOnlineOffline from './OnlineOffline'

export default {
  name: 'TopBar',
  components: { MenuUser, CompanySelector, BoraOnlineOffline, NotificationCompany, InstallPWA },

  computed: {
    ...mapGetters(['user']),

    getAvatar() {
      if (this.user.avatar) {
        return this.user.avatar
      }

      return '/images/no-avatar.jpg'
    },
  },
}
</script>

<template>
  <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
    <div class="container-fluid">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Navbar links -->
        <ul class="navbar-nav align-items-center">
          <li class="nav-item d-xl-none">
            <!-- Sidenav toggler -->
            <div
              class="pr-3 sidenav-toggler sidenav-toggler-dark"
              data-action="sidenav-pin"
              data-target="#sidenav-main"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </div>
          </li>
          <CompanySelector />
        </ul>
        <ul class="navbar-nav align-items-center ml-md-auto">
          <InstallPWA />

          <BoraOnlineOffline />

          <NotificationCompany />
        </ul>
        <ul class="navbar-nav align-items-center ml-auto ml-md-0">
          <li class="nav-item dropdown">
            <a
              class="nav-link pr-0"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img alt="Image placeholder" :src="getAvatar" />
                </span>
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm font-weight-bold">{{ user.name }}</span>
                </div>
              </div>
            </a>

            <MenuUser />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
