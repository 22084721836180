const index = () => import('./components/index')

export default [
  {
    path: '/notificacoes',
    name: 'notifications.index',
    meta: {
      title: 'Notificações',
    },
    component: index,
  },
]
