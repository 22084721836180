const getDiscount = (invoice, filters) => {
  if (invoice.discount_type === 'percentage') {
    let discount = invoice.price * (invoice.discount / 100)
    discount = discount.toFixed(2)
    return `${parseInt(invoice.discount).toFixed(0)} % (${filters.money(discount)})`
  } else {
    return filters.money(invoice.discount)
  }
}

const getPricePaid = (invoice, filters) => {
  let price
  if (invoice.discount_type === 'percentage') {
    const percentage = invoice.discount / 100
    const discount = invoice.price * percentage
    price = invoice.price - discount
  } else {
    price = invoice.price - invoice.discount
  }
  return filters.money(price)
}

const getStatusBadge = (status, lg = false) => {
  switch (status) {
    case 'opened':
      status = `<span class="badge ${lg && 'badge-lg'} badge-warning">Em aberto</span>`
      break
    case 'paid':
      status = `<span class="badge ${lg && 'badge-lg'} badge-success">Pago</span>`
      break
    case 'refunded':
      status = `<span class="badge ${lg && 'badge-lg'} badge-warning">Extornado</span>`
      break
    case 'authorized':
      status = `<span class="badge ${lg && 'badge-lg'} badge-info">Autorizado</span>`
      break
    case 'declined':
      status = `<span class="badge ${lg && 'badge-lg'} badge-danger">Negado</span>`
      break
    case 'failed':
      status = `<span class="badge ${lg && 'badge-lg'} badge-danger">Falha</span>`
      break
    case 'not_authorized':
      status = `<span class="badge ${lg && 'badge-lg'} badge-warning">Não autorizado</span>`
      break
    case 'canceled':
      status = `<span class="badge ${lg && 'badge-lg'} badge-danger">abeCanceladorto</span>`
      break
    case 'due':
      status = `<span class="badge ${lg && 'badge-lg'} badge-danger">Vencido</span>`
      break
    case 'free':
      status = `<span class="badge ${lg && 'badge-lg'} badge-success">Free</span>`
      break
    case 'awaiting_payment':
      status = `<span class="badge ${lg && 'badge-lg'} badge-info">Aguardando pagamento</span>`
      break
  }

  return status
}

export { getDiscount, getPricePaid, getStatusBadge }
