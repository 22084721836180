<script>
export default {
  name: 'File',

  props: {
    name: { type: String, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, default: 'Selecionar uma imagem' },
    preview: { default: null },
    square: { type: Boolean, default: false },
    showInfo: { type: String, default: null },
    showRemove: { type: String, default: null },
    v: { default: () => ({}) },
    textValidations: { default: () => ({}) },
    urlUpload: { type: String, default: null },
    headersUpload: { type: Object, default: null },
    maxWidth: { type: String, default: null },
    accept: { type: String, default: null },
    returnBase64: { type: Boolean, default: false },
  },

  data() {
    return {
      imgPreview: '',
      oldPreview: null,
      oldRemoved: false,
    };
  },

  watch: {
    preview() {
      if (typeof this.preview === 'string') {
        this.imgPreview = this.preview;
        this.oldPreview = this.preview;
      }
      if (this.imgPreview === null && !this.oldRemoved) {
        this.imgPreview = this.oldPreview;
      }
    },
  },

  computed: {
    vIsEmpty() {
      return Object.keys(this.v).length === 0;
    },

    isInvalid() {
      if (this.vIsEmpty) {
        return false;
      }

      return this.isDanger && this.v.$dirty && this.v.$invalid;
    },

    isDanger() {
      if (!this.vIsEmpty) {
        if (!this.imgPreview && !Object.keys(this.textValidations).find(x => x === 'required')) {
          return false;
        }
        return this.v.$dirty && this.v.$invalid;
      }
      return false;
    },
  },

  methods: {
    handleFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.imgPreview = URL.createObjectURL(file);

        if (this.returnBase64) {
          this.base64(e.target.files[0]);
          return;
        }
        this.$emit('input', e.target.files[0]);
      }
    },

    async base64(file) {
      if (file) {
        if (typeof file === 'string') {
          return;
        }
        if (!file.type.includes('image/')) {
          this.$alert.show('Ops', 'Arquivo de imagem inválido.', 'error');
          this.$nextTick(() => {
            this.imgPreview = null;
          });
          return;
        }

        const reader = new FileReader();

        await reader.readAsDataURL(file);

        reader.onloadend = async event => {
          const img = await event.target.result;
          this.$emit('input', img);
        };
      }
    },

    removeFile() {
      this.imgPreview = null;
      this.$refs.input.value = null;

      if (typeof this.preview === 'string' && !this.oldRemoved) {
        this.oldRemoved = true;
      }

      if (typeof this.preview === 'object' && !this.oldRemoved) {
        this.$emit('input', this.oldPreview);
      } else {
        this.$emit('input', null);
      }
    },
  },

  mounted() {
    if (typeof this.preview === 'string') {
      this.imgPreview = this.preview;
      this.oldPreview = this.preview;
    }
    if (this.imgPreview === null && !this.oldRemoved) {
      this.imgPreview = this.oldPreview;
    }
  },
};
</script>

<template>
  <div>
    <div class="info">
      <label class="form-control-label" v-html="label"></label>
      &nbsp;
      <span v-if="showInfo" v-tooltip="showInfo">
        <InformationIcon :size="16" class="text-info" />
      </span>
    </div>

    <input @change="handleFileChange" :accept="accept" ref="input" type="file" />

    <div
      class="area-upload"
      :class="{
        square: square,
        'is-invalid': isInvalid,
      }"
      :style="`max-width: ${maxWidth ? maxWidth : 'auto'}; max-height: 250px`"
    >
      <div
        class="area-clickable"
        :class="{
          'exist-preview': imgPreview,
          'is-invalid': isInvalid,
        }"
        @click="$refs.input.click()"
        v-tooltip="`Clique para ${placeholder.toLowerCase()}`"
      >
        <div class="image-preview" :class="{ square: square }" v-if="!!imgPreview">
          <img :class="{ square: square }" class="preview-img" ref="img" :src="imgPreview" />
        </div>

        <div class="placeholder" :class="{ 'span-hover': !!imgPreview }">{{ placeholder }}</div>
      </div>

      <bora-button
        v-tooltip="showRemove"
        @click="removeFile"
        class="btn-danger btn-sm btn-close"
        v-if="!!imgPreview && showRemove"
      >
        X
      </bora-button>
    </div>

    <div :key="index" v-for="(error, index) in Object.keys(textValidations)">
      <div class="invalid-feedback" v-if="v ? isDanger && !vIsEmpty && !v[error] : false">
        &times; {{ textValidations[error] }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.area-upload {
  position: relative;
  border: 1px dashed #ddd;
  border-radius: 0.375rem;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
}

.area-upload:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.area-clickable {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  color: #8898aa;
  text-align: center;
}

.placeholder:hover {
  color: #ff6600;
}

.preview-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0.375rem;
  object-fit: cover;
}

.area-upload:hover {
  border: 1px dashed #ff8838;
  transition: all 0.3s;
}

input[type='file'] {
  display: none;
}

.btn-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.span-hover {
  color: white !important;
}

.area-upload .exist-preview .placeholder {
  opacity: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.3s;
}

.area-upload .exist-preview .placeholder:hover {
  opacity: 0.85;
  transition: all 0.3s;
}

.area-upload.is-invalid {
  border-color: #f5365c !important;
}

.is-invalid {
  border-color: #f5365c !important;
  color: #f5365c !important;
}

.invalid-feedback {
  display: block;
}
</style>
