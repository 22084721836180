const DeliveryAreas = () => import('./components/index.vue')

export default [
  {
    path: '/areas-de-entrega',
    name: 'delivery.areas',
    meta: {
      title: 'Áreas de entrega'
    },
    component: DeliveryAreas
  }
]
