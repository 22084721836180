<script>
export default {
  name: 'InstallPWA',

  data() {
    return {
      installPrompt: null,
    }
  },

  methods: {
    disableInAppInstallPrompt() {
      this.installPrompt = null
      document.querySelector('#install-pwa').classList.add('d-none')
    },
  },

  mounted() {
    const liButton = document.querySelector('#install-pwa')
    const buttonInstall = document.querySelector('#btn-install-pwa')

    window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault()
      this.installPrompt = event

      if (window.matchMedia('(display-mode: standalone)').matches) {
        return
      }
      liButton.classList.remove('d-none')
    })

    buttonInstall.addEventListener('click', async e => {
      e.preventDefault()

      if (!this.installPrompt) {
        return
      }

      await this.installPrompt.prompt()

      this.disableInAppInstallPrompt()
    })

    window.addEventListener('appinstalled', () => {
      this.disableInAppInstallPrompt()
    })
  },
}
</script>

<template>
  <li class="nav-item d-none" id="install-pwa">
    <bora-button type="button" id="btn-install-pwa" class="nav-link btn btn-white text-primary mr-3">
      <span class="text">Instalar PWA</span>
    </bora-button>
  </li>
</template>
