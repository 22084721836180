const MenuIndex = () => import('./components/index.vue')

export default [
  {
    path: '/menu',
    name: 'menu.index',
    meta: {
      title: 'Produtos',
    },
    component: MenuIndex,
  },
]
