<script>
import MenuNavigation from './MenuNavigation'

export default {
  name: 'NavBar',
  components: { MenuNavigation },

  data() {
    return {
      appName: process.env.APP_NAME
    }
  },

  methods: {
    logout() {
      alert('oi')
    }
  }
}
</script>

<template>
  <perfect-scrollbar
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
    id="sidenav-main"
  >
    <div class="scrollbar-inner">
      <!-- Brand -->
      <div class="sidenav-header d-flex align-items-center">
        <router-link class="navbar-brand" :to="{name: 'dashboard'}">
          <img src="/images/logo.png" class="navbar-brand-img" alt="..." />
        </router-link>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div
            class="sidenav-toggler d-none d-xl-block"
            data-action="sidenav-unpin"
            data-target="#sidenav-main"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-inner">
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Nav items -->

          <MenuNavigation />
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<style scoped>
.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-height: 3rem;
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 4rem;
  }
}
</style>
