import * as types from './mutations-types'
import { localStorageSetItem, localStorageGetItem } from '~/shared/local-storage'

export const setAuth = (context, payload) => {
  context.commit(types.SET_TOKEN, payload.token)
  context.commit(types.SET_USER, payload.user)
  context.commit(types.SET_PERMISSIONS, payload.permissions)
  context.commit(types.SET_COMPANIES, payload.companies)

  localStorageSetItem('token', payload.token)
}

export const updateStatusCompany = (context, payload) => {
  context.commit(types.UPDATE_STATUS_COMPANY, payload)
}

export const updateNextCloseAtCompany = (context, payload) => {
  context.commit(types.UPDATE_NEXT_CLOSE_AT, payload)
}

export const updateDataAuth = (context, payload) => {
  context.commit(types.SET_USER, payload.user)
  context.commit(types.SET_COMPANIES, payload.companies)
  context.commit(types.SET_PERMISSIONS, payload.permissions)
  context.commit(types.UPDATE_SETTINGS, payload.settings)

  context.state.companies.forEach(item => {
    if (item.id === context.state.company.id) {
      context.dispatch('setCompany', item)
      context.commit(types.SET_COMPANY, item)
    }
  })
}

export const setCompany = (context, payload) => {
  context.commit(types.SET_COMPANY, payload)
  localStorageSetItem('company', payload)
}

export const setCompanies = (context, payload) => {
  context.commit(types.SET_COMPANIES, payload)
}

export const setDataFromLocalStorage = context => {
  context.commit(types.SET_TOKEN, localStorageGetItem('token'))
  context.commit(types.SET_COMPANY, localStorageGetItem('company'))
}

export const logout = context => {
  context.commit(types.SET_TOKEN, null)
  context.commit(types.SET_USER, {})
  context.commit(types.SET_PERMISSIONS, [])
  context.commit(types.SET_COMPANIES, [])
  context.commit(types.SET_COMPANY, {})

  localStorageSetItem('token', null)
  localStorageSetItem('company', {})
}
