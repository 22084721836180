import Vue from 'vue'
import { formatDate as utilFormatDate } from '../utils'
import { parseISO, format } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

export default Vue.filter('date', (date, formatDate = null, fromNow = false) => {
  if (!date) {
    return '-'
  }

  let dateFormatted

  if (!formatDate) {
    if (date.indexOf(':') !== -1) {
      dateFormatted = utilFormatDate(date, 'dateHourFormatted')
    } else {
      dateFormatted = utilFormatDate(date, 'dateFormatted')
    }
  }

  if (formatDate) {
    const dateISO = parseISO(date)
    return format(dateISO, formatDate, { locale: pt })
  }

  if (fromNow) {
    dateFormatted = utilFormatDate(date, 'distanceNow')
  }

  return dateFormatted
})
