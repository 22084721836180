import http from '@/http'
import store from '../store'

http.interceptors.request.use(
  response => {
    const { company, token } = store.getters

    if (token && token !== '') {
      response.headers.common.Authorization = `Bearer ${token}`
      if (company) {
        response.headers.common['company-id'] = company.id
      }
      response.headers.common.timezone = window.timezone
    }

    return response
  },

  error => {
    return Promise.reject(error)
  },
)

http.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
      window.location = '/'
    }

    return Promise.reject(error)
  },
)
