import * as types from './mutations-types'

export const setQzConnected = (context, payload) => {
  context.commit(types.SET_QZ_CONNECTED, payload)
}

export const addDeliverymen = (context, payload = null) => {
  context.commit(types.ADD_DELIVERYMEN, payload)
}

export const removeDeliverymen = context => {
  context.commit(types.REMOVE_DELIVERYMEN)
}
