<script>
export default {
  props: {
    status: { type: String, required: true },
  },

  data() {
    return {
      text: '',
      color: '',
    }
  },

  mounted() {
    let text
    switch (this.status) {
      case 'CANCELED':
        text = 'cancelado'
        this.color = 'danger'
        break
      case 'PENDING':
        text = 'em aberto'
        this.color = 'warning'
        break
      case 'AUTHORIZED':
        text = 'autorizado'
        this.color = 'info'
        break
      case 'RECEIVED':
        text = 'pago'
        this.color = 'success'
        break
      case 'CONFIRMED':
        text = 'confirmado'
        this.color = 'success'
        break
      case 'OVERDUE':
        text = 'vencido'
        this.color = 'warning'
        break
      case 'REFUNDED':
        text = 'estornado'
        this.color = 'danger'
        break
      case 'RECEIVED_IN_CASH':
        text = 'recebido em dinheiro'
        this.color = 'success'
        break
      case 'AWAITING_RISK_ANALYSIS':
        text = 'aguardando análise de risco'
        this.color = 'warning'
        break

      default:
        text = ''
    }

    this.text = text
  },
}
</script>

<template>
  <span :class="`badge badge-${this.color}`">{{ text }}</span>
</template>
