import Vue from 'vue'

export default Vue.filter('cpf_cnpj', (value) => {
  let cpfCnpj = value.toString()
  if (cpfCnpj.length === 11) {
    cpfCnpj = `${cpfCnpj.substr(0, 3)}.${cpfCnpj.substr(3, 3)}.${cpfCnpj.substr(6, 3)}-${cpfCnpj.substr(9, 2)}`
  } else {
    cpfCnpj = `${cpfCnpj.substr(0, 2)}.${cpfCnpj.substr(2, 3)}.${cpfCnpj.substr(5, 3)}/${cpfCnpj.substr(8, 4)}-${cpfCnpj.substr(12, 2)}`
  }

  return cpfCnpj
})
