import * as types from './mutations-types'

export const setOrders = (context, payload) => {
  context.commit(types.SET_ORDERS, payload)
}

export const setOrdersPendents = (context, payload) => {
  context.commit(types.SET_ORDERS_PENDENTS, payload)
}

export const addOrder = (context, payload) => {
  context.commit(types.ADD_ORDER, payload)
}

export const openOrder = (context, payload) => {
  context.commit(types.OPEN_ORDER, payload)
}

export const updateOrder = (context, { id, payload }) => {
  context.commit(types.UPDATE_ORDER, { id, payload })
}

export const cancelOrder = (context, id) => {
  context.commit(types.CANCEL_ORDER, id)
}
