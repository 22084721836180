<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuNavigation',

  computed: {
    ...mapGetters(['company']),

    phone() {
      return this.company?.city?.whatsapp_support
    },

    ordersOpened() {
      return this.$store.getters.getCountTotalOpenedOrders
    },
  },
}
</script>

<template>
  <div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link :to="{ name: 'dashboard' }" class="nav-link">
          <DesktopMacDashboardIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Dashboard</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.type !== 'service'">
        <router-link :to="{ name: 'orders' }" class="nav-link">
          <CartIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Pedidos</span>
          <div
            v-if="ordersOpened > 0"
            id="electron-orders-open-count"
            class="badge badge-warning"
            style="position: absolute; right: 10px; font-size: 75%;"
          >
            {{ ordersOpened }}
          </div>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'evaluations.index' }" class="nav-link">
          <StarIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Avaliações</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.type !== 'service'">
        <router-link :to="{ name: 'finances' }" class="nav-link">
          <FinanceIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Financeiro</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'invoices.index' }" class="nav-link">
          <ReceiptIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Faturas</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.type !== 'service'">
        <router-link :to="{ name: 'menu.index' }" class="nav-link">
          <SilverwareIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Produtos</span>
        </router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link :to="{ name: 'reports' }" class="nav-link">
          <ChartBarIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Relatórios</span>
        </router-link>
      </li> -->
      <li class="nav-item">
        <router-link :to="{ name: 'opening.hours' }" class="nav-link">
          <ClockIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Horário de funcionamento</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.calc_delivery === 'radius'">
        <router-link :to="{ name: 'radius.index' }" class="nav-link">
          <MapIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Raios de entrega</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.calc_delivery === 'neighborhood'">
        <router-link :to="{ name: 'neighborhoods.index' }" class="nav-link">
          <MapIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Entregas por bairro</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.calc_delivery === 'area'">
        <router-link :to="{ name: 'delivery.areas' }" class="nav-link">
          <MapIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Entregas por áreas</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'type.payments' }" class="nav-link">
          <CreditCardIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Formas de pagamento</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'bank-account.index' }" class="nav-link">
          <BankIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Dados bancários</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'profile.company' }" class="nav-link">
          <AccountIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Perfil</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="company.type !== 'service'">
        <router-link :to="{ name: 'settings' }" class="nav-link">
          <CogIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Configurações</span>
        </router-link>
      </li>
    </ul>
    <hr class="my-3" />
    <h6 class="navbar-heading p-0 text-muted">
      <span class="docs-normal">Serviços</span>
      <span class="docs-mini">S</span>
    </h6>
    <ul class="navbar-nav">
      <!-- <li class="nav-item">
        <router-link :to="{ name: 'dashboard' }" class="nav-link">
          <NewspaperIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Notícias</span>
        </router-link>
      </li> -->
      <li class="nav-item" v-if="!!phone">
        <a :href="`https://wa.me/55${phone}`" class="nav-link" target="_blank">
          <TicketIcon :size="18" class="mr-2" />
          <span class="nav-link-text">&nbsp; Suporte</span>
        </a>
      </li>
    </ul>
  </div>
</template>
