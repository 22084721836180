export const SET_TOKEN = 'auth/setToken'

export const SET_USER = 'auth/setUser'

export const SET_PERMISSIONS = 'auth/setPermissions'

export const SET_COMPANY = 'auth/setCompany'

export const UPDATE_STATUS_COMPANY = 'auth/updateStatusCompany'
export const UPDATE_NEXT_CLOSE_AT = 'auth/updateNextCloseAt'

export const SET_COMPANIES = 'auth/setCompanies'

export const UPDATE_USER = 'auth/updateUser'

export const UPDATE_SETTINGS = 'auth/updateSettings'
