import iziToast from 'izitoast'
require('izitoast/dist/css/iziToast.css')

const show = context => {
  return {
    show(title, message, type, icon = null, image = null, timeout = 5000) {
      if (type === 'show') {
        iziToast.show({
          id: 'haduken',
          theme: 'dark',
          icon,
          title: title,
          message: message,
          position: 'topCenter',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          progressBarColor: 'rgb(0, 255, 184)',
          image,
          imageWidth: 70,
          timeout,
          layout: 2,
          onClosing: function() {
            console.info('onClosing')
          },
          onClosed: function(instance, toast, closedBy) {
            console.info('Closed | closedBy: ' + closedBy)
          },
        })
      } else {
        iziToast[type]({
          id: 'success',
          title: title,
          message: message,
          position: 'topRight',
          transitionIn: 'bounceInLeft',
        })
      }
    },
  }
}

export default show
