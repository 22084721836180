<script>
import InformationIcon from 'vue-material-design-icons/Information'
import http from '~/http'
import errorsFormat from '../../shared/errors-format'

export default {
  name: 'InputFile',
  components: { InformationIcon },
  props: {
    label: { type: String, required: true },
    name: { type: String, required: true },
    placeholder: { type: String, default: 'Selecionar uma imagem' },
    preview: { default: null },
    square: { type: Boolean, default: false },
    showInfo: { type: String, default: null },
    showRemove: { type: String, default: null },
    v: { default: '' },
    text_validations: { default: '' },
    urlUpload: { type: String, default: null },
    headersUpload: { type: Object, default: null },
    maxWidth: { type: String, default: null },
    accept: { type: String, default: null },
  },

  data() {
    return {
      imgPreview: null,
      oldPreview: null,
      oldRemoved: false,
      percentageUpload: 0,
      showProgress: false,
    }
  },

  watch: {
    preview: function() {
      if (typeof this.preview === 'string') {
        this.imgPreview = this.preview
        this.oldPreview = this.preview
      }
      if (this.imgPreview === null && !this.oldRemoved) {
        this.imgPreview = this.oldPreview
      }
    },

    imgPreview: function(val) {
      const div = document.querySelector(`#imgPreview_${this.name}`)
      let className = 'dropzone dropzone-single mb-3 dz-clickable'

      if (val) {
        className = 'dropzone dropzone-single mb-3 dz-clickable dz-max-files-reached'
      }

      div.className = className + (this.square ? ' square dropzone-square' : '')
    },
  },

  computed: {
    isDanger() {
      if (this.v) {
        if (this.v.form[this.name]) {
          if (!this.imgPreview && !Object.keys(this.text_validations).find(x => x === 'required')) {
            return false
          }
          return this.v.form[this.name].$dirty && this.v.form[this.name].$invalid
        }
      }
      return false
    },
  },

  methods: {
    handleFileChange(e) {
      const file = e.target.files[0]
      if (file) {
        if (this.urlUpload) {
          this.onUpload(file)
          return
        }

        this.imgPreview = URL.createObjectURL(file)

        if (file.type.split('/')[0] !== 'image') {
          this.imgPreview = '/images/file-generic.png'
        }

        this.$emit('input', e.target.files[0])
      }
    },

    removeFile() {
      this.imgPreview = null
      this.$refs[this.name].value = null

      if (typeof this.preview === 'string' && !this.oldRemoved) {
        this.oldRemoved = true
      }

      if (typeof this.preview === 'object' && !this.oldRemoved) {
        this.$emit('input', this.oldPreview)
      } else {
        this.$emit('input', null)
      }
    },

    onUpload(file) {
      this.showProgress = true

      const formData = new FormData()
      formData.append(this.name, file)

      http
        .post(this.urlUpload, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...this.headersUpload,
          },
          onUploadProgress: ev => {
            this.percentageUpload = parseInt(Math.round((ev.loaded * 100) / ev.total))
          },
        })
        .then(response => {
          alert('upload complete')
        })
        .catch(error => {
          this.$alert.show('Ops', errorsFormat(error), 'error')
        })
        .finally(() => {
          this.showProgress = false
        })
    },
  },
}
</script>

<template>
  <div>
    <div class="info">
      <label :for="name" class="form-control-label" v-html="label"></label>
      &nbsp;
      <span v-if="showInfo" v-tooltip="showInfo">
        <InformationIcon :size="16" class="text-info" />
      </span>
    </div>

    <div
      :class="{ square: square, 'dropzone-square': square, 'dz-max-files-reached': showProgress }"
      class="dropzone dropzone-single mb-3 dz-clickable"
      :id="`imgPreview_${name}`"
      :style="(!square ? 'height: 200px;' : '') + (maxWidth ? `max-width: ${maxWidth};` : '')"
      data-toggle="dropzone"
      data-dropzone-url="http://"
    >
      <div class="dz-preview dz-preview-single" :class="{ square: square, 'flex-center': showProgress }">
        <div class="progress" v-if="showProgress" style="width: 50%;">
          <div
            class="progress-bar"
            role="progressbar"
            :style="`width: ${percentageUpload}%;`"
            :aria-valuenow="percentageUpload"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div v-if="!showProgress" class="dz-preview-cover dz-processing dz-image-preview" :class="square && 'square'">
          <img
            :class="{ square: square, 'file-generic': imgPreview === '/images/file-generic.png' }"
            class="dz-preview-img"
            :src="imgPreview ? imgPreview : '/images/file-generic.png'"
            alt
          />
        </div>
      </div>
      <input @change="handleFileChange" :accept="accept" :ref="name" type="file" :name="name" :id="name" />
      <div
        v-tooltip="`Clique para ${placeholder.toLowerCase()}`"
        @click="$refs[name].click()"
        class="dz-default dz-message"
        :class="{ square: square, 'is-invalid': isDanger && v.form[name].$dirty && v.form[name].$invalid }"
      >
        <span :class="{ 'span-hover': !!imgPreview }">{{ placeholder }}</span>
      </div>
      <bora-button
        v-tooltip="showRemove"
        @click="removeFile"
        class="btn-danger btn-sm"
        v-if="!!imgPreview && showRemove"
        >X</bora-button
      >
    </div>

    <div :key="index" v-for="(error, index) in Object.keys(text_validations)">
      <div class="invalid-feedback" v-if="v ? isDanger && !v.form[name][error] : false">
        &times; {{ text_validations[error] }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.info {
  flex-direction: row;
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type='file'] {
  display: none;
}

.file-generic {
  margin-top: 32px;
  width: 150px !important;
  height: 150px !important;
}

.square {
  width: 100%;
}

@media (min-width: 768px) {
  .square {
    width: 100%;
  }
}

label {
  display: block;
}

.invalid-feedback {
  display: block;
}

.btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1070;
}

.is-invalid {
  border-color: #f5365c !important;
  color: #f5365c !important;
}

.dropzone-square {
  border: 1px dashed #dee2e6;
  border-radius: 0.375rem;
  width: 100%;
}

.dropzone-square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.dz-preview {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dz-message {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dropzone {
  border: 1px dashed #dee2e6;
  border-radius: 0.375rem;
}

.dropzone:hover {
  border-color: rgb(255, 159, 90);
}

.dz-message .span-hover {
  color: white !important;
}

.dz-message {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-align: center;
  transition: all 0.15s ease-in;
  color: #8898aa;
  border-radius: 0.375rem;
  background-color: #fff;

  order: -1;
}
.dz-message:hover {
  color: #525f7f;
  border-color: rgb(255, 159, 90);
}

.dz-drag-hover .dz-message {
  color: #5e72e4;
  border-color: #5e72e4;
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dropzone-single.dz-max-files-reached .dz-message {
  opacity: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
}
.dropzone-single.dz-max-files-reached .dz-message:hover {
  opacity: 0.85;
}

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;

  border-radius: 0.375rem;
}

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 0.375rem;
}

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
}

.dz-preview-img {
  width: 100%;
  height: 100%;

  border-radius: 0.375rem;

  -o-object-fit: cover;
  object-fit: cover;
}

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;

  border-bottom: 0;
}

[data-dz-size] strong {
  font-weight: 400;
}
</style>
