import show from './alert'

const install = (Vue, store) => {
  Object.defineProperty(Vue.prototype, '$alert', {
    get() {
      return show(this)
    }
  })
}

export default { install }
