export default ($this, { component, props = {}, styles = {}, receiveProps = {} }) => {
  $this.$modal.show(
    component,
    {
      ...props,
    },
    {
      height: 'auto',
      width: '100%',
      scrollable: true,
      adaptive: true,
      'max-width': 1000,
      ...styles,
    },
    {
      ...receiveProps,
    },
  )
}
