export default function(canceledBy) {
  switch (canceledBy) {
    case 'user':
      return 'Usuário'
    case 'system':
      return 'Sistema'
    case 'licensed':
      return 'Licenciado'
    case 'franchisee':
      return 'Licenciado'
    case 'company':
      return 'Empresa'
    default:
      return 'Desconhecido'
  }
}
