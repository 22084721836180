import * as types from './mutations-types'

export default {
  [types.SET_ORDERS](state, orders) {
    state.orders = orders
    state.loaded = true
  },

  [types.ADD_ORDER](state, order) {
    const index = state.orders.findIndex(o => o.id === order.id)

    if (index === -1) {
      state.orders.unshift({
        ...order,
        opened: false,
      })
    } else {
      state.orders = state.orders.map(o => {
        if (o.id === order.id) {
          return { ...o, ...order }
        }
        return o
      })
    }
  },

  [types.OPEN_ORDER](state, order) {
    state.orders = state.orders.map(or => {
      if (order.id === or.id) {
        return { ...or, opened: true }
      }
      return or
    })
  },

  // Change status order
  [types.UPDATE_ORDER](state, { id, payload }) {
    state.orders = state.orders.map(order => {
      if (order.id === id) {
        return { ...order, ...payload }
      }
      return order
    })
  },

  // Cancel order
  [types.CANCEL_ORDER](state, id) {
    state.orders = state.orders.map(order => {
      if (order.id === id) {
        return { ...order, status: 'canceled' }
      }
      return order
    })
  },

  [types.SET_ORDERS_PENDENTS](state, total) {
    state.pendents = total
  },
}
