const Login = () => import('./components/login.vue')
const RecoveryPassword = () => import('./components/recovery-password.vue')
const SelectCompany = () => import('./components/select-company.vue')

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'clean',
      title: 'Acessar',
    },
    component: Login,
  },
  {
    path: '/recuperar-senha',
    name: 'recovery.password',
    meta: {
      layout: 'clean',
      title: 'Recuperar senha',
    },
    component: RecoveryPassword,
  },
  {
    path: '/selecionar-empresa',
    name: 'set.company',
    meta: {
      title: 'Selecionar empresa',
    },
    component: SelectCompany,
  },
]
