const Index = () => import('./components/index')

export default [
  {
    path: '/dados-bancarios',
    name: 'bank-account.index',
    meta: {
      title: 'Dados bancários',
    },
    component: Index,
  },
]
