/*****************************************
 *
 * Function BlitzMap()
 * This function initializes the BlitzMap
 *
 *****************************************/
/* eslint-disable */

window.BlitzMap = new function() {
  var mapObj, optionsMap, drwManager, infWindow, currentMapIndex
  var mapOverlays = new Array()
  var isEditable = false
  var notifyErrors = true
  var colorPicker
  var mapContainerId, sideBar, mapDiv, mapStorageId
  var routeUnit = 'metric'
  var dirRenderer
  var dirService
  var dirTravelMode
  var dirAvoidHighways = false
  var dirAvoidTolls = false
  var dirProvideRouteAlternatives = false
  var dirRouteUnit
  var dirOptimizeWaypoints = false
  var geoXml = null

  /*****************************************
   *
   * Function Init()
   * This function initializes the BlitzMap
   *
   *****************************************/
  this.init = function() {
    var mapOptions = optionsMap || {
      center: new google.maps.LatLng(-10.192254, -50.302146),
      zoom: 5,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }

    // create a common infoWindow object
    infWindow = new google.maps.InfoWindow()

    if (isEditable) {
      // initialize a common Drawing Manager object
      // we will use only one Drawing Manager
      drwManager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON]
        },
        markerOptions: { editable: true, draggable: true }, // markers created are editable by default
        circleOptions: { editable: true }, // circles created are editable by default
        rectangleOptions: { editable: true }, // rectangles created are editable by default
        polygonOptions: { editable: true }, // polygons created are editable by default
        polylineOptions: { editable: true } // polylines created are editable by default
      })
    }

    if (mapDiv) {
      mapObj = new google.maps.Map(mapDiv, mapOptions)

      infWindow.setMap(mapObj)
      if (isEditable) {
        drwManager.setMap(mapObj)
        google.maps.event.addListener(infWindow, 'domready', pickColor)
        google.maps.event.addListener(
          drwManager,
          'overlaycomplete',
          overlayDone
        )
      }

      if (mapStorageId) {
        // mapData is passed in a HTML input as JSON string
        // create overlays using that data
        setMapData(document.getElementById(mapStorageId).value)
      }

      // var ctaLayer = new google.maps.KmlLayer('http://possible.in/test3.kml');
      // ctaLayer.setMap(mapObj);
      dirRenderer = new google.maps.DirectionsRenderer()
      dirRenderer.setMap(mapObj)
      dirRenderer.setPanel(
        document.getElementById(mapContainerId + '_directions')
      )
      dirService = new google.maps.DirectionsService()
      dirTravelMode = google.maps.TravelMode.DRIVING
      dirAvoidHighways = false
      dirAvoidTolls = false
      dirProvideRouteAlternatives = true
      dirRouteUnit = google.maps.UnitSystem.METRIC
      dirOptimizeWaypoints = true
    }
  }

  /**************************************************
   * function setMap()
   * parameters:
   * 		divId	: String, Id of HTML DIV element in which the gMap will be created
   * 		edit	: Boolean(optional:default=false), tells you if the map objects can be edited or not
   * 		inputId : String(optional), Id of HTML element which will be used to store/pass the serialized MAP data
   *
   **************************************************/
  this.setMap = function(divId, edit, inputId) {
    if (typeof divId === 'string') {
      if (document.getElementById(divId)) {
        mapContainerId = divId
        mapDiv = document.createElement('div')
        mapDiv.id = divId + '_map'
        setStyle(mapDiv, {
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: 1,
          left: '0'
        })

        document.getElementById(mapContainerId).appendChild(mapDiv)

        sideBar = document.createElement('div')
        sideBar.id = divId + '_sidebar'
        setStyle(sideBar, {
          height: '100%',
          width: '250px',
          display: 'none',
          backgroundColor: '#e6e6e6',
          borderLeft: '5px solid #999',
          position: 'absolute',
          zIndex: '1',
          right: '0',
          fontFamily: 'Arial',
          overflowY: 'auto'
        })

        document.getElementById(mapContainerId).appendChild(sideBar)
        setStyle(document.getElementById(mapContainerId), {
          position: 'relative'
        })
        sideBar.innerHTML =
          '<div style="padding:10px 0 0 26px;">' +
          '<style> div#' +
          sideBar.id +
          ' a.travelMode{ height:37px;width:32px;display:block;float:left;margin:0; background-position:bottom;background-repeat:no-repeat;outline:0;}' +
          ' div#' +
          sideBar.id +
          ' a.travelMode:hover{ cursor:pointer; background-position:top;}' +
          ' div#' +
          sideBar.id +
          ' span.route_row_menu{ font-size:12px;font-family:Arial; color:#ff0000; cursor:pointer; } ' +
          ' div#' +
          mapContainerId +
          '_route div.route_row span{ width:20px;height:20px;display:inline-block; text-align:center; } ' +
          ' div#' +
          mapContainerId +
          '_route_options{ font-size:12px; } ' +
          ' div#' +
          mapContainerId +
          '_directions{ font-size:12px; }' +
          '</style>' +
          '<a id="' +
          mapContainerId +
          '_mode_drive" href="javascript:void(0)" class="travelMode" style="background-image:url(images/car.png);background-position:top;" onclick="BlitzMap.setTravelMode( google.maps.TravelMode.DRIVING, this )" ></a>' +
          '<a id="' +
          mapContainerId +
          '_mode_walk" href="javascript:void(0)" class="travelMode" style="background-image:url(images/walk.png);" onclick="BlitzMap.setTravelMode( google.maps.TravelMode.WALKING, this)"></a>' +
          '<a id="' +
          mapContainerId +
          '_mode_bicycle" href="javascript:void(0)" class="travelMode" style="background-image:url(images/bicycle.png);" onclick="BlitzMap.setTravelMode( google.maps.TravelMode.BICYCLING, this )"></a>' +
          // + '<a id="'+divId + '_mode_public" href="javascript:void(0)" class="travelMode" style="background-image:url(images/public.png);" onclick="BlitzMap.setTravelMode( google.maps.TravelMode.PUBLIC, this )"></a>'
          '<div style="clear:both;"></div>' +
          '</div>' +
          '<div id="' +
          mapContainerId +
          '_route" style="margin:5px 5px 5px;">' +
          '<div id="' +
          mapContainerId +
          '_route_row_0" class="route_row"><span id="' +
          mapContainerId +
          '_route_row_0_title">A</span> <input  id="' +
          mapContainerId +
          '_route_row_0_dest" type="text" /><img id="' +
          mapContainerId +
          '_route_row_0_remove" alt="X" height="20" width="20" onclick="BlitzMap.removeDestination(this)" style="cursor:pointer;display:none;" /></div>' +
          '<div id="' +
          mapContainerId +
          '_route_row_1" class="route_row"><span id="' +
          mapContainerId +
          '_route_row_1_title">B</span> <input  id="' +
          mapContainerId +
          '_route_row_1_dest" type="text" /><img id="' +
          mapContainerId +
          '_route_row_1_remove" alt="X" height="20" width="20" onclick="BlitzMap.removeDestination(this)" style="cursor:pointer;display:none;" /></div>' +
          '</div>' +
          '<div id="' +
          mapContainerId +
          '_route_menu" style="margin:5px 5px 5px 30px;">' +
          '<span class="route_row_menu" onclick="BlitzMap.addDestination()">Add destination</span> - ' +
          '<span id="' +
          mapContainerId +
          '_route_opt_btn" class="route_row_menu" onclick="BlitzMap.toggleRouteOptions()">Show Options</span>' +
          '</div>' +
          '<div id="' +
          mapContainerId +
          '_route_options" style="margin:5px 5px;display:none;">' +
          '<div style="float:right">' +
          '<span id="' +
          mapContainerId +
          '_route_unit_km" onclick="BlitzMap.setRouteUnit( google.maps.UnitSystem.METRIC )">Km</span> / ' +
          '<span id="' +
          mapContainerId +
          '_route_unit_mi" class="route_row_menu"  onclick="BlitzMap.setRouteUnit( google.maps.UnitSystem.IMPERIAL )">Miles</span>' +
          '</div>' +
          '<div style="margin-left:20px">' +
          '<input id="' +
          mapContainerId +
          '_route_avoid_hw" type="checkbox" value="avoidHighways" onclick="BlitzMap.setAvoidHighways(this)" /><label for="' +
          mapContainerId +
          '_route_avoid_hw">Avoid highways</label><br/>' +
          '<input id="' +
          mapContainerId +
          '_route_avoid_toll" type="checkbox" value="avoidTolls" onclick="BlitzMap.setAvoidTolls(this)" /><label for="' +
          mapContainerId +
          '_route_avoid_toll">Avoid tolls</label> ' +
          '</div>' +
          '</div>' +
          '<div style="margin:0 0 10px 30px">' +
          '<input type="button" onclick="BlitzMap.getRoute()" value="Get Directions">' +
          '</div>' +
          '<div style="clear:both;"></div>' +
          '<div id="' +
          mapContainerId +
          '_directions" style="">' +
          '</div>'
      } else {
        notify(
          'BlitzMap Error: The DIV id you supplied for generating GMap is not present in the document.'
        )
      }
    } else {
      notify(
        'BlitzMap Error: The DIV id you supplied for generating GMap is invalid. It should be a string representing the Id of Div element in which you want to create the map.'
      )
    }

    if (edit == true) {
      isEditable = true
    }

    if (typeof inputId === 'string') {
      if (document.getElementById(inputId)) {
        mapStorageId = inputId
      } else {
        notify(
          'BlitzMap Error: The INPUT id you supplied for storing the JSON string is not present in the document.'
        )
      }
    }
  }

  this.setAvoidHighways = function(obj) {
    dirAvoidHighways = obj.checked
  }

  this.setAvoidTolls = function(obj) {
    dirAvoidTolls = obj.checked
  }

  this.setTravelMode = function(mode, menuObj) {
    dirTravelMode = mode
    setStyle(document.getElementById(mapContainerId + '_mode_drive'), {
      backgroundPosition: 'bottom'
    })
    setStyle(document.getElementById(mapContainerId + '_mode_walk'), {
      backgroundPosition: 'bottom'
    })
    setStyle(document.getElementById(mapContainerId + '_mode_bicycle'), {
      backgroundPosition: 'bottom'
    })
    setStyle(menuObj, { backgroundPosition: 'top' })
  }

  this.getRoute = function() {
    var start, end
    var waypts = []
    var routeDiv = document.getElementById(mapContainerId + '_route')

    for (var i = 0; i < routeDiv.children.length; i++) {
      if (i == 0) {
        start = routeDiv.children[i].children[1].value
      } else if (i == routeDiv.children.length - 1) {
        end = routeDiv.children[i].children[1].value
      } else {
        waypts.push({
          location: routeDiv.children[i].children[1].value,
          stopover: true
        })
      }
    }

    var request = {
      origin: start,
      destination: end,
      waypoints: waypts,
      optimizeWaypoints: false,
      travelMode: dirTravelMode,
      avoidHighways: dirAvoidHighways,
      avoidTolls: dirAvoidTolls,
      provideRouteAlternatives: dirProvideRouteAlternatives,
      unitSystem: dirRouteUnit,
      optimizeWaypoints: dirOptimizeWaypoints
    }

    dirService.route(request, function(response, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        dirRenderer.setDirections(response)
        /* var summaryPanel = document.getElementById( mapContainerId + '_directions' );
		      summaryPanel.innerHTML = "";
		      for( var j=0; j < response.routes.length; j++ ) {
			      var route = response.routes[j];

			      // For each route, display summary information.
			      for (var i = 0; i < route.legs.length; i++) {
			        var routeSegment = i+1;
			        summaryPanel.innerHTML += "<b>Route Segment: " + routeSegment + "</b><br />";
			        summaryPanel.innerHTML += route.legs[i].start_address + " to ";
			        summaryPanel.innerHTML += route.legs[i].end_address + "<br />";
			        summaryPanel.innerHTML += route.legs[i].distance.text + "<br /><br />";
			      }
		      } */
      }
    })
  }

  this.addDestination = function() {
    var routeDiv = document.getElementById(mapContainerId + '_route')
    var routeNum = routeDiv.children.length
    if (routeNum == 8) {
      alert(
        'You have reached maximum number of destinations that can be searched with Google Directions API.'
      )
      return
    }
    var newDest = document.createElement('div')
    newDest.id = mapContainerId + '_route_row_' + routeNum.toString()
    newDest.className = 'route_row'

    newDest.innerHTML =
      '<span id="' +
      mapContainerId +
      '_route_row_' +
      routeNum +
      '_title">' +
      String.fromCharCode(65 + routeNum) +
      '</span> ' +
      '<input id="' +
      mapContainerId +
      '_route_row_' +
      routeNum +
      '_dest" type="text" />' +
      '<img id="' +
      mapContainerId +
      '_route_row_' +
      routeNum +
      '_remove" alt="X" height="20" width="20" onclick="BlitzMap.removeDestination(this)" style="cursor:pointer" />'
    routeDiv.appendChild(newDest)
    for (var i = 0; i < routeDiv.children.length; i++) {
      if (i < 2 && routeDiv.children.length <= 2) {
        routeDiv.children[i].children[2].style.display = 'none'
      } else {
        routeDiv.children[i].children[2].style.display = 'inline'
      }
    }
  }

  this.removeDestination = function(obj) {
    var rowDiv = obj.parentNode
    var routeDiv = rowDiv.parentNode
    routeDiv.removeChild(rowDiv)

    for (var i = 0; i < routeDiv.children.length; i++) {
      routeDiv.children[i].id = mapContainerId + '_route_row_' + i
      routeDiv.children[i].children[0].id =
        mapContainerId + '_route_row_' + i + '_title'
      routeDiv.children[i].children[0].innerHTML = String.fromCharCode(65 + i)
      routeDiv.children[i].children[1].id =
        mapContainerId + '_route_row_' + i + '_dest'

      routeDiv.children[i].children[2].id =
        mapContainerId + '_route_row_' + i + '_remove'
      if (i < 2 && routeDiv.children.length <= 2) {
        routeDiv.children[i].children[2].style.display = 'none'
      } else {
        routeDiv.children[i].children[2].style.display = 'inline'
      }
    }
  }

  this.setRouteUnit = function(unt) {
    dirRouteUnit = unt
    if (dirRouteUnit == google.maps.UnitSystem.IMPERIAL) {
      document.getElementById(mapContainerId + '_route_unit_mi').className = ''
      document.getElementById(mapContainerId + '_route_unit_km').className =
        'route_row_menu'
    } else {
      document.getElementById(mapContainerId + '_route_unit_mi').className =
        'route_row_menu'
      document.getElementById(mapContainerId + '_route_unit_km').className = ''
    }
  }

  this.toggleRouteOptions = function() {
    if (
      getStyle(
        document.getElementById(mapContainerId + '_route_options'),
        'display'
      ) == 'block'
    ) {
      setStyle(document.getElementById(mapContainerId + '_route_options'), {
        display: 'none'
      })
      document.getElementById(mapContainerId + '_route_opt_btn').innerHTML =
        'Show Options'
    } else {
      setStyle(document.getElementById(mapContainerId + '_route_options'), {
        display: 'block'
      })
      document.getElementById(mapContainerId + '_route_opt_btn').innerHTML =
        'Hide Options'
    }
  }

  this.setCenter = function(latlng) {
    mapObj.setCenter(latlng)
  }

  this.setZoom = function(zoom) {
    mapObj.setZoom(zoom)
  }

  this.getMapRef = function() {
    return mapObj
  }

  this.fitBounds = function(bounds) {
    mapObj.fitBounds(bounds)
  }

  function overlayDone(event) {
    var uniqueid = uniqid()
    event.overlay.uniqueid = uniqueid
    event.overlay.title = ''
    event.overlay.content = ''
    event.overlay.type = event.type
    mapOverlays.push(event.overlay)
    AttachClickListener(event.overlay)
    openInfowindow(
      event.overlay,
      getShapeCenter(event.overlay),
      getEditorContent(event.overlay)
    )
  }

  function getShapeCenter(shape) {
    if (shape.type == 'marker') {
      return shape.position
    } else if (shape.type == 'circle') {
      return shape.getCenter()
    } else if (shape.type == 'rectangle') {
      return new google.maps.LatLng(
        (shape
          .getBounds()
          .getSouthWest()
          .lat() +
          shape
            .getBounds()
            .getNorthEast()
            .lat()) /
          2,
        (shape
          .getBounds()
          .getSouthWest()
          .lng() +
          shape
            .getBounds()
            .getNorthEast()
            .lng()) /
          2
      )
    } else if (shape.type == 'polygon') {
      return shape
        .getPaths()
        .getAt(0)
        .getAt(0)
    } else if (shape.type == 'polyline') {
      return shape.getPath().getAt(Math.round(shape.getPath().getLength() / 3))
    }
  }

  function AttachClickListener(overlay) {
    google.maps.event.addListener(overlay, 'click', function(clkEvent) {
      if (isEditable) {
        var infContent = getEditorContent(overlay)
      } else {
        var infContent = GetContent(overlay)
      }

      openInfowindow(overlay, clkEvent.latLng, infContent)
    })
  }

  function GetContent(overlay) {
    var content =
      '<div><h3>' +
      overlay.title +
      '</h3>' +
      overlay.content +
      '<br></div>' +
      GetInfoWindowFooter(overlay)
    return content
  }

  function GetInfoWindowFooter(overlay) {
    var content =
      '<div id="' +
      mapContainerId +
      '_dirContainer" style="bottom:0;padding-top:3px; font-size:13px;font-family:arial">'
    return content
  }

  this.getDirections = function() {
    setStyle(sideBar, { display: 'block' })
    setStyle(mapDiv, { width: '70%' })
    google.maps.event.trigger(mapObj, 'resize')
    // mapObj.panTo( mapObj.getBounds() );
  }

  function openInfowindow(overlay, latLng, content) {
    var div = document.createElement('div')
    div.innerHTML = content
    setStyle(div, { height: '100%' })
    infWindow.setContent(div)
    infWindow.setPosition(latLng)
    infWindow.relatedOverlay = overlay
    var t = overlay.get('fillColor')
    infWindow.open(mapObj)
  }

  function getEditorContent(overlay) {
    var content = `
    <form>
      <div class="form-group">
        <label for="title" class="form-control-label">Título</label>
        <input type="text" class="form-control" id="BlitzMapInfoWindow_title" value="${
          overlay.title
        }" />
      </div>
      <div class="form-group">
        <label for="title" class="form-control-label">Descrição</label>
        <textarea id="BlitzMapInfoWindow_content" class="form-control">${
          overlay.content
        }</textarea>
      </div>
    `

    if (
      overlay.type == 'polygon' ||
      overlay.type == 'circle' ||
      overlay.type == 'rectangle'
    ) {
      var fillColor =
        overlay.fillColor == undefined ? '#ff6600' : overlay.fillColor
      content += `<input type="hidden" id="BlitzMapInfoWindow_fillcolor" value="${fillColor}">`

      var fillOpacity =
        overlay.fillOpacity == undefined ? 0.3 : overlay.fillOpacity
      content += `<input type="hidden" id="BlitzMapInfoWindow_fillopacity" value="${fillOpacity.toString()}">`
    }
    if (overlay.type != 'marker') {
      var strokeColor =
        overlay.strokeColor == undefined ? '#000' : overlay.strokeColor
      content += `<input type="hidden" id="BlitzMapInfoWindow_strokecolor" value="${strokeColor}">`
      var strokeOpacity =
        overlay.strokeOpacity == undefined ? 0.9 : overlay.strokeOpacity
      content += `<input type="hidden" id="BlitzMapInfoWindow_strokeopacity" value="${strokeOpacity.toString()}">`

      var strokeWeight =
        overlay.strokeWeight == undefined ? 3 : overlay.strokeWeight
      content += `<input type="hidden" id="BlitzMapInfoWindow_strokeweight" value="${strokeWeight.toString()}">`
    } else {
      // var strokeColor = ( overlay.strokeColor == undefined )? "#ff6600":overlay.strokeColor;
      // content += '<div style="height:25px;padding-bottom:3px;">Line Color: <input type="text" id="BlitzMapInfoWindow_strokecolor" value="'+ strokeColor +'" style="border:2px solid #dddddd;width:30px;height:20px;font-size:0;float:right" ></div>';

      // var animation = overlay.getAnimation();
      // content += '<div style="height:25px;padding-bottom:3px;">Line Opacity(percent): <select id="BlitzMapInfoWindow_animation" style="border:2px solid #dddddd;width:30px;float:right" ><option value="none">None</option><option value="bounce">Bounce</option><option value="drop">Drop</option></div>';

      var icon = overlay.icon == undefined ? '' : overlay.icon
      content +=
        '<div style="height:25px;padding-bottom:3px;">Icon(): <input type="text" id="BlitzMapInfoWindow_icon" value="' +
        icon.toString() +
        '" style="border:2px solid #dddddd;width:100px;float:right" ></div>'
    }
    content += `
    <div class="form-group">
      <button class="btn btn-sm btn-danger" type="button" onclick="BlitzMap.deleteOverlay()">Excluir</button>
      <div class="float-right">
        <button type="button" class="btn btn-default btn-sm" onclick="this.form.reset();BlitzMap.closeInfoWindow()">Cancelar</button>
        <button type="button" class="btn btn-primary btn-sm" onclick="BlitzMap.closeInfoWindow(); BlitzMap.setEditable()">Salvar</button>
      </div>
    </div>
  </form>
  `

    return content
  }

  function pickColor() {
    if (document.getElementById('BlitzMapInfoWindow_fillcolor')) {
      var bgcolor = new jscolor.color(
        document.getElementById('BlitzMapInfoWindow_fillcolor'),
        {}
      )
    }
    if (document.getElementById('BlitzMapInfoWindow_strokecolor')) {
      var bdColor = new jscolor.color(
        document.getElementById('BlitzMapInfoWindow_strokecolor'),
        {}
      )
    }
  }

  this.deleteOverlay = function() {
    infWindow.relatedOverlay.setMap(null)
    infWindow.close()
  }

  this.closeInfoWindow = function() {
    this.updateOverlay()
    infWindow.close()
  }

  this.updateOverlay = function() {
    infWindow.relatedOverlay.title = document.getElementById(
      'BlitzMapInfoWindow_title'
    ).value
    infWindow.relatedOverlay.content = document.getElementById(
      'BlitzMapInfoWindow_content'
    ).value

    if (
      infWindow.relatedOverlay.type == 'polygon' ||
      infWindow.relatedOverlay.type == 'circle' ||
      infWindow.relatedOverlay.type == 'rectangle'
    ) {
      infWindow.relatedOverlay.setOptions({
        fillColor:
          '#' +
          document
            .getElementById('BlitzMapInfoWindow_fillcolor')
            .value.replace('#', '')
      })
      setStyle(document.getElementById('BlitzMapInfoWindow_fillcolor'), {
        'background-color':
          '#' +
          document
            .getElementById('BlitzMapInfoWindow_fillcolor')
            .value.replace('#', '')
      })

      infWindow.relatedOverlay.setOptions({
        fillOpacity: Number(
          document.getElementById('BlitzMapInfoWindow_fillopacity').value
        )
      })
    }

    if (infWindow.relatedOverlay.type != 'marker') {
      infWindow.relatedOverlay.setOptions({
        strokeColor:
          '#' +
          document
            .getElementById('BlitzMapInfoWindow_strokecolor')
            .value.replace('#', '')
      })

      infWindow.relatedOverlay.setOptions({
        strokeOpacity: Number(
          document.getElementById('BlitzMapInfoWindow_strokeopacity').value
        )
      })

      infWindow.relatedOverlay.setOptions({
        strokeWeight: Number(
          document.getElementById('BlitzMapInfoWindow_strokeweight').value
        )
      })
    } else {
      infWindow.relatedOverlay.setOptions({
        icon: document.getElementById('BlitzMapInfoWindow_icon').value
      })
    }
  }

  this.toggleStyleEditor = function() {
    var tmp = document.getElementById('BlitzMapInfoWindow_details')
    var tmp1 = document.getElementById('BlitzMapInfoWindow_styles')
    if (tmp) {
      if (getStyle(tmp, 'display') == 'none') {
        setStyle(tmp1, { display: 'none' })
        document.getElementById('BlitzMapInfoWindow_toggle').value =
          'Customize Colors>>'
        setStyle(tmp, { display: 'block' })
      } else {
        setStyle(tmp, { display: 'none' })
        document.getElementById('BlitzMapInfoWindow_toggle').value = 'Back>>'
        setStyle(tmp1, { display: 'block' })
      }
    }
  }

  function notify(msg) {
    if (notifyErrors) {
      alert(msg)
    }
  }

  function uniqid() {
    var newDate = new Date()
    return newDate.getTime()
  }

  function setMapData(jsonString) {
    if (jsonString.length == 0) {
      return false
    }
    var inputData = JSON.parse(jsonString)
    if (inputData.zoom) {
      mapObj.setZoom(inputData.zoom)
    } else {
      mapObj.setZoom(10)
    }

    if (inputData.tilt) {
      mapObj.setTilt(inputData.tilt)
    } else {
      mapObj.setTilt(0)
    }

    if (inputData.mapTypeId) {
      mapObj.setMapTypeId(inputData.mapTypeId)
    } else {
      mapObj.setMapTypeId('hybrid')
    }

    if (inputData.center) {
      mapObj.setCenter(
        new google.maps.LatLng(inputData.center.lat, inputData.center.lng)
      )
    } else {
      mapObj.setCenter(new google.maps.LatLng(19.006295, 73.309021))
    }

    var tmpOverlay, ovrOptions
    var properties = new Array(
      'fillColor',
      'fillOpacity',
      'strokeColor',
      'strokeOpacity',
      'strokeWeight',
      'icon'
    )
    for (var m = inputData.overlays.length - 1; m >= 0; m--) {
      ovrOptions = new Object()

      for (var x = properties.length; x >= 0; x--) {
        if (inputData.overlays[m][properties[x]]) {
          ovrOptions[properties[x]] = inputData.overlays[m][properties[x]]
        }
      }

      if (inputData.overlays[m].type == 'polygon') {
        var tmpPaths = new Array()
        for (var n = 0; n < inputData.overlays[m].paths.length; n++) {
          var tmpPath = new Array()
          for (var p = 0; p < inputData.overlays[m].paths[n].length; p++) {
            tmpPath.push(
              new google.maps.LatLng(
                inputData.overlays[m].paths[n][p].lat,
                inputData.overlays[m].paths[n][p].lng
              )
            )
          }
          tmpPaths.push(tmpPath)
        }
        ovrOptions.paths = tmpPaths
        tmpOverlay = new google.maps.Polygon(ovrOptions)
      } else if (inputData.overlays[m].type == 'polyline') {
        var tmpPath = new Array()
        for (var p = 0; p < inputData.overlays[m].path.length; p++) {
          tmpPath.push(
            new google.maps.LatLng(
              inputData.overlays[m].path[p].lat,
              inputData.overlays[m].path[p].lng
            )
          )
        }
        ovrOptions.path = tmpPath
        tmpOverlay = new google.maps.Polyline(ovrOptions)
      } else if (inputData.overlays[m].type == 'rectangle') {
        var tmpBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(
            inputData.overlays[m].bounds.sw.lat,
            inputData.overlays[m].bounds.sw.lng
          ),
          new google.maps.LatLng(
            inputData.overlays[m].bounds.ne.lat,
            inputData.overlays[m].bounds.ne.lng
          )
        )
        ovrOptions.bounds = tmpBounds
        tmpOverlay = new google.maps.Rectangle(ovrOptions)
      } else if (inputData.overlays[m].type == 'circle') {
        var cntr = new google.maps.LatLng(
          inputData.overlays[m].center.lat,
          inputData.overlays[m].center.lng
        )
        ovrOptions.center = cntr
        ovrOptions.radius = inputData.overlays[m].radius
        tmpOverlay = new google.maps.Circle(ovrOptions)
      } else if (inputData.overlays[m].type == 'marker') {
        var pos = new google.maps.LatLng(
          inputData.overlays[m].position.lat,
          inputData.overlays[m].position.lng
        )
        ovrOptions.position = pos
        if (inputData.overlays[m].icon) {
          ovrOptions.icon = inputData.overlays[m].icon
        }
        if (isEditable) {
          ovrOptions.draggable = true
        }
        tmpOverlay = new google.maps.Marker(ovrOptions)
      }
      tmpOverlay.type = inputData.overlays[m].type
      tmpOverlay.setMap(mapObj)
      if (isEditable && inputData.overlays[m].type != 'marker') {
        tmpOverlay.setEditable(true)
      }

      var uniqueid = uniqid()
      tmpOverlay.uniqueid = uniqueid
      if (inputData.overlays[m].title) {
        tmpOverlay.title = inputData.overlays[m].title
      } else {
        tmpOverlay.title = ''
      }

      if (inputData.overlays[m].content) {
        tmpOverlay.content = inputData.overlays[m].content
      } else {
        tmpOverlay.content = ''
      }

      // attach the click listener to the overlay
      AttachClickListener(tmpOverlay)

      // save the overlay in the array
      mapOverlays.push(tmpOverlay)
    }
  }

  this.setEditable = function(editable) {
    isEditable = editable
    for (var i = 0; i < mapOverlays.length; i++) {
      if (mapOverlays[i].getMap() != null) {
        mapOverlays[i].setOptions({ editable: isEditable })
      }
    }
  }

  this.toggleEditable = function() {
    isEditable = !isEditable
    for (var i = 0; i < mapOverlays.length; i++) {
      if (mapOverlays[i].getMap() != null) {
        if (mapOverlays[i].setEditable) mapOverlays[i].setEditable(isEditable)
      }
    }
  }

  this.setMapFromEncoded = function(encodedString) {
    if (encodedString.length == 0) {
      return false
    }
    if (typeof google.maps.geometry.encoding.decodePath === 'undefined') {
      // check for include of geometry library
      // https://developers.google.com/maps/documentation/javascript/geometry
      alert('geoxml3.js not included')
      return
    }
    var pointsArray = google.maps.geometry.encoding.decodePath(encodedString)
    var tmpBounds = new google.maps.LatLngBounds()
    for (var i = 0; i < pointsArray.length; i++) {
      tmpBounds.extend(pointsArray[i])
    }
    var tmpOverlay
    var ovrOptions = new Object()
    var properties = new Array(
      'fillColor',
      'fillOpacity',
      'strokeColor',
      'strokeOpacity',
      'strokeWeight',
      'icon'
    )
    ovrOptions.strokeWidth = 2
    ovrOptions.strokeColor = '#0000FF'
    ovrOptions.strokeOpacity = 0.8
    ovrOptions.fillColor = '#0000FF'
    ovrOptions.fillOpacity = 0.2
    ovrOptions.paths = [pointsArray]
    tmpOverlay = new google.maps.Polygon(ovrOptions)

    tmpOverlay.type = 'polygon'
    tmpOverlay.setMap(mapObj)
    mapObj.fitBounds(tmpBounds)
    tmpOverlay.setEditable(true)

    var uniqueid = uniqid()
    tmpOverlay.uniqueid = uniqueid
    tmpOverlay.title = ''
    tmpOverlay.content = ''

    // attach the click listener to the overlay
    AttachClickListener(tmpOverlay)

    // save the overlay in the array
    mapOverlays.push(tmpOverlay)
  }
  this.setMapFromEncodedLine = function(encodedString) {
    if (encodedString.length == 0) {
      return false
    }
    if (typeof google.maps.geometry.encoding.decodePath === 'undefined') {
      // check for include of geometry library
      // https://developers.google.com/maps/documentation/javascript/geometry
      alert('geoxml3.js not included')
      return
    }
    var pointsArray = google.maps.geometry.encoding.decodePath(encodedString)
    var tmpBounds = new google.maps.LatLngBounds()
    for (var i = 0; i < pointsArray.length; i++) {
      tmpBounds.extend(pointsArray[i])
    }
    var tmpOverlay
    var ovrOptions = new Object()
    var properties = new Array(
      'fillColor',
      'fillOpacity',
      'strokeColor',
      'strokeOpacity',
      'strokeWeight',
      'icon'
    )
    ovrOptions.strokeWidth = 2
    ovrOptions.strokeColor = '#0000FF'
    ovrOptions.strokeOpacity = 0.8
    ovrOptions.fillColor = '#0000FF'
    ovrOptions.fillOpacity = 0.2
    ovrOptions.path = pointsArray
    tmpOverlay = new google.maps.Polyline(ovrOptions)

    tmpOverlay.type = 'polyline'
    tmpOverlay.setMap(mapObj)
    mapObj.fitBounds(tmpBounds)
    tmpOverlay.setEditable(true)

    var uniqueid = uniqid()
    tmpOverlay.uniqueid = uniqueid
    tmpOverlay.title = ''
    tmpOverlay.content = ''

    // attach the click listener to the overlay
    AttachClickListener(tmpOverlay)

    // save the overlay in the array
    mapOverlays.push(tmpOverlay)
  }
  this.setMapFromKML = function(kmlString) {
    if (kmlString.length == 0) {
      return false
    }
    if (typeof geoXML3 === 'undefined') {
      // check for include of geoxml3 parser
      // http://code.google.com/p/geoxml3/
      alert('geoxml3.js not included')
      return
    }
    if (!geoXml) {
      geoXml = new geoXML3.parser({
        map: mapObj,
        zoom: false,
        suppressInfoWindows: true
      })
    }

    geoXml.parseKmlString(kmlString)

    var tmpOverlay, ovrOptions
    for (var m = 0; m < geoXml.docs[0].placemarks.length; m++) {
      if (geoXml.docs[0].placemarks[m].Polygon) {
        tmpOverlay = geoXml.docs[0].placemarks[m].polygon
        if (isEditable) {
          tmpOverlay.setEditable(true)
        }
        tmpOverlay.type = 'polygon'
      } else if (geoXml.docs[0].placemarks[m].LineString) {
        tmpOverlay = geoXml.docs[0].placemarks[m].polyline
        if (isEditable) {
          tmpOverlay.setEditable(true)
        }
        tmpOverlay.type = 'polyline'
      } else if (geoXml.docs[0].placemarks[m].Point) {
        tmpOverlay = geoXml.docs[0].placemarks[m].marker
        tmpOverlay.type = 'marker'
      }

      var uniqueid = uniqid()
      tmpOverlay.uniqueid = uniqueid
      if (geoXml.docs[0].placemarks[m].name) {
        tmpOverlay.title = geoXml.docs[0].placemarks[m].name
      } else {
        tmpOverlay.title = ''
      }

      if (geoXml.docs[0].placemarks[m].description) {
        tmpOverlay.content = geoXml.docs[0].placemarks[m].description
      } else {
        tmpOverlay.content = ''
      }

      // attach the click listener to the overlay
      AttachClickListener(tmpOverlay)

      // save the overlay in the array
      mapOverlays.push(tmpOverlay)
    }
    mapObj.fitBounds(geoXml.docs[0].bounds)
  }

  this.deleteAll = function() {
    for (var i = 0; i < mapOverlays.length; i++) {
      mapOverlays[i].setMap(null)
    }
    mapOverlays = []
  }

  function mapToObject() {
    var tmpMap = new Object()
    var tmpOverlay, paths
    tmpMap.zoom = mapObj.getZoom()
    tmpMap.tilt = mapObj.getTilt()
    tmpMap.mapTypeId = mapObj.getMapTypeId()
    tmpMap.center = {
      lat: mapObj.getCenter().lat(),
      lng: mapObj.getCenter().lng()
    }
    tmpMap.overlays = new Array()

    for (var i = 0; i < mapOverlays.length; i++) {
      if (mapOverlays[i].getMap() == null) {
        continue
      }
      tmpOverlay = new Object()
      tmpOverlay.type = mapOverlays[i].type
      tmpOverlay.title = mapOverlays[i].title
      tmpOverlay.content = mapOverlays[i].content

      if (mapOverlays[i].fillColor) {
        tmpOverlay.fillColor = mapOverlays[i].fillColor
      }

      if (mapOverlays[i].fillOpacity) {
        tmpOverlay.fillOpacity = mapOverlays[i].fillOpacity
      }

      if (mapOverlays[i].strokeColor) {
        tmpOverlay.strokeColor = mapOverlays[i].strokeColor
      }

      if (mapOverlays[i].strokeOpacity) {
        tmpOverlay.strokeOpacity = mapOverlays[i].strokeOpacity
      }

      if (mapOverlays[i].strokeWeight) {
        tmpOverlay.strokeWeight = mapOverlays[i].strokeWeight
      }

      if (mapOverlays[i].icon) {
        tmpOverlay.icon = mapOverlays[i].icon
      }

      if (mapOverlays[i].flat) {
        tmpOverlay.flat = mapOverlays[i].flat
      }

      if (mapOverlays[i].type == 'polygon') {
        tmpOverlay.paths = new Array()
        paths = mapOverlays[i].getPaths()
        for (var j = 0; j < paths.length; j++) {
          tmpOverlay.paths[j] = new Array()
          for (var k = 0; k < paths.getAt(j).length; k++) {
            tmpOverlay.paths[j][k] = {
              lat: paths
                .getAt(j)
                .getAt(k)
                .lat()
                .toString(),
              lng: paths
                .getAt(j)
                .getAt(k)
                .lng()
                .toString()
            }
          }
        }
      } else if (mapOverlays[i].type == 'polyline') {
        tmpOverlay.path = new Array()
        path = mapOverlays[i].getPath()
        for (var j = 0; j < path.length; j++) {
          tmpOverlay.path[j] = {
            lat: path
              .getAt(j)
              .lat()
              .toString(),
            lng: path
              .getAt(j)
              .lng()
              .toString()
          }
        }
      } else if (mapOverlays[i].type == 'circle') {
        tmpOverlay.center = {
          lat: mapOverlays[i].getCenter().lat(),
          lng: mapOverlays[i].getCenter().lng()
        }
        tmpOverlay.radius = mapOverlays[i].radius
      } else if (mapOverlays[i].type == 'rectangle') {
        tmpOverlay.bounds = {
          sw: {
            lat: mapOverlays[i]
              .getBounds()
              .getSouthWest()
              .lat(),
            lng: mapOverlays[i]
              .getBounds()
              .getSouthWest()
              .lng()
          },
          ne: {
            lat: mapOverlays[i]
              .getBounds()
              .getNorthEast()
              .lat(),
            lng: mapOverlays[i]
              .getBounds()
              .getNorthEast()
              .lng()
          }
        }
      } else if (mapOverlays[i].type == 'marker') {
        tmpOverlay.position = {
          lat: mapOverlays[i].getPosition().lat(),
          lng: mapOverlays[i].getPosition().lng()
        }
      }
      tmpMap.overlays.push(tmpOverlay)
    }

    return tmpMap
  }

  this.toJSONString = function() {
    var result = JSON.stringify(mapToObject())

    if (mapStorageId) {
      document.getElementById(mapStorageId).value = result
    }

    return result
  }

  this.toKML = function() {
    var result = mapToObject()
    var xw = new XMLWriter('UTF-8')
    xw.formatting = 'indented' // add indentation and newlines
    xw.indentChar = ' ' // indent with spaces
    xw.indentation = 2 // add 2 spaces per level

    xw.writeStartDocument()
    xw.writeStartElement('kml')
    xw.writeAttributeString('xmlns', 'http://www.opengis.net/kml/2.2')
    xw.writeStartElement('Document')

    for (var i = 0; i < result.overlays.length; i++) {
      xw.writeStartElement('Placemark')
      xw.writeStartElement('name')
      xw.writeCDATA(result.overlays[i].title)
      xw.writeEndElement()
      xw.writeStartElement('description')
      xw.writeCDATA(result.overlays[i].content)
      xw.writeEndElement()
      if (result.overlays[i].type == 'marker') {
        xw.writeStartElement('Point')
        xw.writeElementString('extrude', '1')
        xw.writeElementString('altitudeMode', 'relativeToGround')
        xw.writeElementString(
          'coordinates',
          result.overlays[i].position.lng.toString() +
            ',' +
            result.overlays[i].position.lat.toString() +
            ',0'
        )
        xw.writeEndElement()
      } else if (
        result.overlays[i].type == 'polygon' ||
        result.overlays[i].type == 'rectangle' ||
        result.overlays[i].type == 'circle'
      ) {
        xw.writeStartElement('Polygon')
        xw.writeElementString('extrude', '1')
        xw.writeElementString('altitudeMode', 'relativeToGround')

        if (result.overlays[i].type == 'rectangle') {
          // its a polygon
          xw.writeStartElement('outerBoundaryIs')
          xw.writeStartElement('LinearRing')
          xw.writeStartElement('coordinates')
          xw.writeString(
            result.overlays[i].bounds.sw.lng +
              ',' +
              result.overlays[i].bounds.sw.lat +
              ',0'
          )
          xw.writeString(
            result.overlays[i].bounds.ne.lng +
              ',' +
              result.overlays[i].bounds.sw.lat +
              ',0'
          )
          xw.writeString(
            result.overlays[i].bounds.ne.lng +
              ',' +
              result.overlays[i].bounds.ne.lat +
              ',0'
          )
          xw.writeString(
            result.overlays[i].bounds.sw.lng +
              ',' +
              result.overlays[i].bounds.ne.lat +
              ',0'
          )
          xw.writeEndElement()
          xw.writeEndElement()
          xw.writeEndElement()
        }
        if (result.overlays[i].type == 'circle') {
          // its a polygon, approximate a circle by a circular 64 sided polygon.
          xw.writeStartElement('outerBoundaryIs')
          xw.writeStartElement('LinearRing')
          xw.writeStartElement('coordinates')
          var d2r = Math.PI / 180 // degrees to radians
          var r2d = 180 / Math.PI // radians to degrees
          var earthsradius = 6378137 // 6378137 is the radius of the earth in meters
          var dir = 1 // clockwise

          var points = 64

          // find the raidus in lat/lon
          var rlat = (result.overlays[i].radius / earthsradius) * r2d
          var rlng = rlat / Math.cos(result.overlays[i].center.lat * d2r)

          var extp = new Array()
          if (dir == 1) {
            var start = 0
            var end = points + 1
          } // one extra here makes sure we connect the line
          else {
            var start = points + 1
            var end = 0
          }
          for (var j = start; dir == 1 ? j < end : j > end; j = j + dir) {
            var theta = Math.PI * (j / (points / 2))
            ey = result.overlays[i].center.lng + rlng * Math.cos(theta) // center a + radius x * cos(theta)
            ex = result.overlays[i].center.lat + rlat * Math.sin(theta) // center b + radius y * sin(theta)
            xw.writeString(ey + ',' + ex + ',0')
          }
          xw.writeEndElement()
          xw.writeEndElement()
          xw.writeEndElement()
        } else {
          for (var j = 0; j < result.overlays[i].paths.length; j++) {
            if (j == 0) {
              xw.writeStartElement('outerBoundaryIs')
            } else {
              xw.writeStartElement('innerBoundaryIs')
            }
            xw.writeStartElement('LinearRing')
            xw.writeStartElement('coordinates')
            for (var k = 0; k < result.overlays[i].paths[j].length; k++) {
              xw.writeString(
                result.overlays[i].paths[j][k].lng +
                  ',' +
                  result.overlays[i].paths[j][k].lat +
                  ',0'
              )
            }
            xw.writeEndElement()
            xw.writeEndElement()
            xw.writeEndElement()
          }
        }
        xw.writeEndElement()
      } else if (result.overlays[i].type == 'polyline') {
        xw.writeStartElement('LineString')
        xw.writeElementString('extrude', '1')
        xw.writeElementString('altitudeMode', 'relativeToGround')
        xw.writeStartElement('coordinates')
        for (var j = 0; j < result.overlays[i].path.length; j++) {
          xw.writeString(
            result.overlays[i].path[j].lng +
              ',' +
              result.overlays[i].path[j].lat +
              ',0'
          )
        }
        xw.writeEndElement()
        xw.writeEndElement()
      }

      xw.writeEndElement() // END PlaceMarker
    }

    xw.writeEndElement()
    xw.writeEndElement()
    xw.writeEndDocument()

    var xml = xw.flush() // generate the xml string
    xw.close() // clean the writer
    xw = undefined // don't let visitors use it, it's closed
    // set the xml
    document.getElementById('kmlString').value = xml
  }

  function getStyle(elem, prop) {
    if (document.defaultView && document.defaultView.getComputedStyle) {
      return document.defaultView
        .getComputedStyle(elem, null)
        .getPropertyValue(prop)
    } else if (elem.currentStyle) {
      var ar = prop.match(/\w[^-]*/g)
      var s = ar[0]
      for (var i = 1; i < ar.length; ++i) {
        s += ar[i].replace(/\w/, ar[i].charAt(0).toUpperCase())
      }
      return elem.currentStyle[s]
    } else {
      return 0
    }
  }

  function setStyle(domElem, styleObj) {
    if (typeof styleObj === 'object') {
      for (var prop in styleObj) {
        domElem.style[prop] = styleObj[prop]
      }
    }
  }
}()

google.maps.event.addDomListener(window, 'load', BlitzMap.init)

/**
 * jscolor, JavaScript Color Picker
 *
 * @version 1.3.11
 * @license GNU Lesser General Public License, http://www.gnu.org/copyleft/lesser.html
 * @author  Jan Odvarko, http://odvarko.cz
 * @created 2008-06-15
 * @updated 2011-11-07
 * @link    http://jscolor.com
 */

var jscolor = {
  dir: '', // location of jscolor directory (leave empty to autodetect)
  bindClass: 'color', // class name
  binding: true, // automatic binding via <input class="...">
  preloading: true, // use image preloading?

  install: function() {
    jscolor.addEvent(window, 'load', jscolor.init)
  },

  init: function() {
    if (jscolor.binding) {
      jscolor.bind()
    }
    if (jscolor.preloading) {
      jscolor.preload()
    }
  },

  getDir: function() {
    if (!jscolor.dir) {
      var detected = jscolor.detectDir()
      jscolor.dir = detected !== false ? detected : 'jscolor/'
    }
    return jscolor.dir
  },

  detectDir: function() {
    var base = location.href

    var e = document.getElementsByTagName('base')
    for (var i = 0; i < e.length; i += 1) {
      if (e[i].href) {
        base = e[i].href
      }
    }

    var e = document.getElementsByTagName('script')
    for (var i = 0; i < e.length; i += 1) {
      if (e[i].src && /(^|\/)jscolor\.js([?#].*)?$/i.test(e[i].src)) {
        var src = new jscolor.URI(e[i].src)
        var srcAbs = src.toAbsolute(base)
        srcAbs.path = srcAbs.path.replace(/[^\/]+$/, '') // remove filename
        srcAbs.query = null
        srcAbs.fragment = null
        return srcAbs.toString()
      }
    }
    return false
  },

  bind: function() {
    var matchClass = new RegExp(
      '(^|\\s)(' + jscolor.bindClass + ')\\s*(\\{[^}]*\\})?',
      'i'
    )
    var e = document.getElementsByTagName('input')
    for (var i = 0; i < e.length; i += 1) {
      var m
      if (
        !e[i].color &&
        e[i].className &&
        (m = e[i].className.match(matchClass))
      ) {
        var prop = {}
        if (m[3]) {
          try {
            eval('prop=' + m[3])
          } catch (eInvalidProp) {}
        }
        e[i].color = new jscolor.color(e[i], prop)
      }
    }
  },

  preload: function() {
    for (var fn in jscolor.imgRequire) {
      if (jscolor.imgRequire.hasOwnProperty(fn)) {
        jscolor.loadImage(fn)
      }
    }
  },

  images: {
    pad: [181, 101],
    sld: [16, 101],
    cross: [15, 15],
    arrow: [7, 11]
  },

  imgRequire: {},
  imgLoaded: {},

  requireImage: function(filename) {
    jscolor.imgRequire[filename] = true
  },

  loadImage: function(filename) {
    if (!jscolor.imgLoaded[filename]) {
      jscolor.imgLoaded[filename] = new Image()
      jscolor.imgLoaded[filename].src = jscolor.getDir() + filename
    }
  },

  fetchElement: function(mixed) {
    return typeof mixed === 'string' ? document.getElementById(mixed) : mixed
  },

  addEvent: function(el, evnt, func) {
    if (el.addEventListener) {
      el.addEventListener(evnt, func, false)
    } else if (el.attachEvent) {
      el.attachEvent('on' + evnt, func)
    }
  },

  fireEvent: function(el, evnt) {
    if (!el) {
      return
    }
    if (document.createEvent) {
      var ev = document.createEvent('HTMLEvents')
      ev.initEvent(evnt, true, true)
      el.dispatchEvent(ev)
    } else if (document.createEventObject) {
      var ev = document.createEventObject()
      el.fireEvent('on' + evnt, ev)
    } else if (el['on' + evnt]) {
      // alternatively use the traditional event model (IE5)
      el['on' + evnt]()
    }
  },

  getElementPos: function(e) {
    var e1 = e
    var e2 = e
    var x = 0
    var y = 0
    if (e1.offsetParent) {
      do {
        x += e1.offsetLeft
        y += e1.offsetTop
      } while ((e1 = e1.offsetParent))
    }
    while ((e2 = e2.parentNode) && e2.nodeName.toUpperCase() !== 'BODY') {
      x -= e2.scrollLeft
      y -= e2.scrollTop
    }
    return [x, y]
  },

  getElementSize: function(e) {
    return [e.offsetWidth, e.offsetHeight]
  },

  getRelMousePos: function(e) {
    var x = 0
    var y = 0
    if (!e) {
      e = window.event
    }
    if (typeof e.offsetX === 'number') {
      x = e.offsetX
      y = e.offsetY
    } else if (typeof e.layerX === 'number') {
      x = e.layerX
      y = e.layerY
    }
    return { x: x, y: y }
  },

  getViewPos: function() {
    if (typeof window.pageYOffset === 'number') {
      return [window.pageXOffset, window.pageYOffset]
    } else if (
      document.body &&
      (document.body.scrollLeft || document.body.scrollTop)
    ) {
      return [document.body.scrollLeft, document.body.scrollTop]
    } else if (
      document.documentElement &&
      (document.documentElement.scrollLeft ||
        document.documentElement.scrollTop)
    ) {
      return [
        document.documentElement.scrollLeft,
        document.documentElement.scrollTop
      ]
    } else {
      return [0, 0]
    }
  },

  getViewSize: function() {
    if (typeof window.innerWidth === 'number') {
      return [window.innerWidth, window.innerHeight]
    } else if (
      document.body &&
      (document.body.clientWidth || document.body.clientHeight)
    ) {
      return [document.body.clientWidth, document.body.clientHeight]
    } else if (
      document.documentElement &&
      (document.documentElement.clientWidth ||
        document.documentElement.clientHeight)
    ) {
      return [
        document.documentElement.clientWidth,
        document.documentElement.clientHeight
      ]
    } else {
      return [0, 0]
    }
  },

  URI: function(uri) {
    // See RFC3986

    this.scheme = null
    this.authority = null
    this.path = ''
    this.query = null
    this.fragment = null

    this.parse = function(uri) {
      var m = uri.match(
        /^(([A-Za-z][0-9A-Za-z+.-]*)(:))?((\/\/)([^\/?#]*))?([^?#]*)((\?)([^#]*))?((#)(.*))?/
      )
      this.scheme = m[3] ? m[2] : null
      this.authority = m[5] ? m[6] : null
      this.path = m[7]
      this.query = m[9] ? m[10] : null
      this.fragment = m[12] ? m[13] : null
      return this
    }

    this.toString = function() {
      var result = ''
      if (this.scheme !== null) {
        result = result + this.scheme + ':'
      }
      if (this.authority !== null) {
        result = result + '//' + this.authority
      }
      if (this.path !== null) {
        result = result + this.path
      }
      if (this.query !== null) {
        result = result + '?' + this.query
      }
      if (this.fragment !== null) {
        result = result + '#' + this.fragment
      }
      return result
    }

    this.toAbsolute = function(base) {
      var base = new jscolor.URI(base)
      var r = this
      var t = new jscolor.URI()

      if (base.scheme === null) {
        return false
      }

      if (
        r.scheme !== null &&
        r.scheme.toLowerCase() === base.scheme.toLowerCase()
      ) {
        r.scheme = null
      }

      if (r.scheme !== null) {
        t.scheme = r.scheme
        t.authority = r.authority
        t.path = removeDotSegments(r.path)
        t.query = r.query
      } else {
        if (r.authority !== null) {
          t.authority = r.authority
          t.path = removeDotSegments(r.path)
          t.query = r.query
        } else {
          if (r.path === '') {
            // TODO: == or === ?
            t.path = base.path
            if (r.query !== null) {
              t.query = r.query
            } else {
              t.query = base.query
            }
          } else {
            if (r.path.substr(0, 1) === '/') {
              t.path = removeDotSegments(r.path)
            } else {
              if (base.authority !== null && base.path === '') {
                // TODO: == or === ?
                t.path = '/' + r.path
              } else {
                t.path = base.path.replace(/[^\/]+$/, '') + r.path
              }
              t.path = removeDotSegments(t.path)
            }
            t.query = r.query
          }
          t.authority = base.authority
        }
        t.scheme = base.scheme
      }
      t.fragment = r.fragment

      return t
    }

    function removeDotSegments(path) {
      var out = ''
      while (path) {
        if (path.substr(0, 3) === '../' || path.substr(0, 2) === './') {
          path = path.replace(/^\.+/, '').substr(1)
        } else if (path.substr(0, 3) === '/./' || path === '/.') {
          path = '/' + path.substr(3)
        } else if (path.substr(0, 4) === '/../' || path === '/..') {
          path = '/' + path.substr(4)
          out = out.replace(/\/?[^\/]*$/, '')
        } else if (path === '.' || path === '..') {
          path = ''
        } else {
          var rm = path.match(/^\/?[^\/]*/)[0]
          path = path.substr(rm.length)
          out = out + rm
        }
      }
      return out
    }

    if (uri) {
      this.parse(uri)
    }
  },

  /*
   * Usage example:
   * var myColor = new jscolor.color(myInputElement)
   */

  color: function(target, prop) {
    this.required = true // refuse empty values?
    this.adjust = true // adjust value to uniform notation?
    this.hash = false // prefix color with # symbol?
    this.caps = true // uppercase?
    this.slider = true // show the value/saturation slider?
    this.valueElement = target // value holder
    this.styleElement = target // where to reflect current color
    this.onImmediateChange = null // onchange callback (can be either string or function)
    this.hsv = [0, 0, 1] // read-only  0-6, 0-1, 0-1
    this.rgb = [1, 1, 1] // read-only  0-1, 0-1, 0-1

    this.pickerOnfocus = true // display picker on focus?
    this.pickerMode = 'HSV' // HSV | HVS
    this.pickerPosition = 'bottom' // left | right | top | bottom
    this.pickerSmartPosition = true // automatically adjust picker position when necessary
    this.pickerButtonHeight = 20 // px
    this.pickerClosable = false
    this.pickerCloseText = 'Close'
    this.pickerButtonColor = 'ButtonText' // px
    this.pickerFace = 10 // px
    this.pickerFaceColor = 'ThreeDFace' // CSS color
    this.pickerBorder = 1 // px
    this.pickerBorderColor =
      'ThreeDHighlight ThreeDShadow ThreeDShadow ThreeDHighlight' // CSS color
    this.pickerInset = 1 // px
    this.pickerInsetColor =
      'ThreeDShadow ThreeDHighlight ThreeDHighlight ThreeDShadow' // CSS color
    this.pickerZIndex = 10000

    for (var p in prop) {
      if (prop.hasOwnProperty(p)) {
        this[p] = prop[p]
      }
    }

    this.hidePicker = function() {
      if (isPickerOwner()) {
        removePicker()
      }
    }

    this.showPicker = function() {
      if (!isPickerOwner()) {
        var tp = jscolor.getElementPos(target) // target pos
        var ts = jscolor.getElementSize(target) // target size
        var vp = jscolor.getViewPos() // view pos
        var vs = jscolor.getViewSize() // view size
        var ps = getPickerDims(this) // picker size
        var a, b, c
        switch (this.pickerPosition.toLowerCase()) {
          case 'left':
            a = 1
            b = 0
            c = -1
            break
          case 'right':
            a = 1
            b = 0
            c = 1
            break
          case 'top':
            a = 0
            b = 1
            c = -1
            break
          default:
            a = 0
            b = 1
            c = 1
            break
        }
        var l = (ts[b] + ps[b]) / 2

        // picker pos
        if (!this.pickerSmartPosition) {
          var pp = [tp[a], tp[b] + ts[b] - l + l * c]
        } else {
          var pp = [
            -vp[a] + tp[a] + ps[a] > vs[a]
              ? -vp[a] + tp[a] + ts[a] / 2 > vs[a] / 2 &&
                tp[a] + ts[a] - ps[a] >= 0
                ? tp[a] + ts[a] - ps[a]
                : tp[a]
              : tp[a],
            -vp[b] + tp[b] + ts[b] + ps[b] - l + l * c > vs[b]
              ? -vp[b] + tp[b] + ts[b] / 2 > vs[b] / 2 &&
                tp[b] + ts[b] - l - l * c >= 0
                ? tp[b] + ts[b] - l - l * c
                : tp[b] + ts[b] - l + l * c
              : tp[b] + ts[b] - l + l * c >= 0
              ? tp[b] + ts[b] - l + l * c
              : tp[b] + ts[b] - l - l * c
          ]
        }
        drawPicker(pp[a], pp[b])
      }
    }

    this.importColor = function() {
      if (!valueElement) {
        this.exportColor()
      } else {
        if (!this.adjust) {
          if (!this.fromString(valueElement.value, leaveValue)) {
            styleElement.style.backgroundColor =
              styleElement.jscStyle.backgroundColor
            styleElement.style.color = styleElement.jscStyle.color
            this.exportColor(leaveValue | leaveStyle)
          }
        } else if (!this.required && /^\s*$/.test(valueElement.value)) {
          valueElement.value = ''
          styleElement.style.backgroundColor =
            styleElement.jscStyle.backgroundColor
          styleElement.style.color = styleElement.jscStyle.color
          this.exportColor(leaveValue | leaveStyle)
        } else if (this.fromString(valueElement.value)) {
          // OK
        } else {
          this.exportColor()
        }
      }
    }

    this.exportColor = function(flags) {
      if (!(flags & leaveValue) && valueElement) {
        var value = this.toString()
        if (this.caps) {
          value = value.toUpperCase()
        }
        if (this.hash) {
          value = '#' + value
        }
        valueElement.value = value
        BlitzMap.updateOverlay()
      }
      if (!(flags & leaveStyle) && styleElement) {
        styleElement.style.backgroundColor = '#' + this.toString()
        styleElement.style.color =
          0.213 * this.rgb[0] + 0.715 * this.rgb[1] + 0.072 * this.rgb[2] < 0.5
            ? '#FFF'
            : '#000'
      }
      if (!(flags & leavePad) && isPickerOwner()) {
        redrawPad()
      }
      if (!(flags & leaveSld) && isPickerOwner()) {
        redrawSld()
      }
    }

    this.fromHSV = function(h, s, v, flags) {
      // null = don't change
      ;(h < 0 && (h = 0)) || (h > 6 && (h = 6))
      ;(s < 0 && (s = 0)) || (s > 1 && (s = 1))
      ;(v < 0 && (v = 0)) || (v > 1 && (v = 1))
      this.rgb = HSV_RGB(
        h === null ? this.hsv[0] : (this.hsv[0] = h),
        s === null ? this.hsv[1] : (this.hsv[1] = s),
        v === null ? this.hsv[2] : (this.hsv[2] = v)
      )
      this.exportColor(flags)
    }

    this.fromRGB = function(r, g, b, flags) {
      // null = don't change
      ;(r < 0 && (r = 0)) || (r > 1 && (r = 1))
      ;(g < 0 && (g = 0)) || (g > 1 && (g = 1))
      ;(b < 0 && (b = 0)) || (b > 1 && (b = 1))
      var hsv = RGB_HSV(
        r === null ? this.rgb[0] : (this.rgb[0] = r),
        g === null ? this.rgb[1] : (this.rgb[1] = g),
        b === null ? this.rgb[2] : (this.rgb[2] = b)
      )
      if (hsv[0] !== null) {
        this.hsv[0] = hsv[0]
      }
      if (hsv[2] !== 0) {
        this.hsv[1] = hsv[1]
      }
      this.hsv[2] = hsv[2]
      this.exportColor(flags)
    }

    this.fromString = function(hex, flags) {
      var m = hex.match(/^\W*([0-9A-F]{3}([0-9A-F]{3})?)\W*$/i)
      if (!m) {
        return false
      } else {
        if (m[1].length === 6) {
          // 6-char notation
          this.fromRGB(
            parseInt(m[1].substr(0, 2), 16) / 255,
            parseInt(m[1].substr(2, 2), 16) / 255,
            parseInt(m[1].substr(4, 2), 16) / 255,
            flags
          )
        } else {
          // 3-char notation
          this.fromRGB(
            parseInt(m[1].charAt(0) + m[1].charAt(0), 16) / 255,
            parseInt(m[1].charAt(1) + m[1].charAt(1), 16) / 255,
            parseInt(m[1].charAt(2) + m[1].charAt(2), 16) / 255,
            flags
          )
        }
        return true
      }
    }

    this.toString = function() {
      return (
        (0x100 | Math.round(255 * this.rgb[0])).toString(16).substr(1) +
        (0x100 | Math.round(255 * this.rgb[1])).toString(16).substr(1) +
        (0x100 | Math.round(255 * this.rgb[2])).toString(16).substr(1)
      )
    }

    function RGB_HSV(r, g, b) {
      var n = Math.min(Math.min(r, g), b)
      var v = Math.max(Math.max(r, g), b)
      var m = v - n
      if (m === 0) {
        return [null, 0, v]
      }
      var h =
        r === n ? 3 + (b - g) / m : g === n ? 5 + (r - b) / m : 1 + (g - r) / m
      return [h === 6 ? 0 : h, m / v, v]
    }

    function HSV_RGB(h, s, v) {
      if (h === null) {
        return [v, v, v]
      }
      var i = Math.floor(h)
      var f = i % 2 ? h - i : 1 - (h - i)
      var m = v * (1 - s)
      var n = v * (1 - s * f)
      switch (i) {
        case 6:
        case 0:
          return [v, n, m]
        case 1:
          return [n, v, m]
        case 2:
          return [m, v, n]
        case 3:
          return [m, n, v]
        case 4:
          return [n, m, v]
        case 5:
          return [v, m, n]
      }
    }

    function removePicker() {
      delete jscolor.picker.owner
      document.getElementsByTagName('body')[0].removeChild(jscolor.picker.boxB)
    }

    function drawPicker(x, y) {
      if (!jscolor.picker) {
        jscolor.picker = {
          box: document.createElement('div'),
          boxB: document.createElement('div'),
          pad: document.createElement('div'),
          padB: document.createElement('div'),
          padM: document.createElement('div'),
          sld: document.createElement('div'),
          sldB: document.createElement('div'),
          sldM: document.createElement('div'),
          btn: document.createElement('div'),
          btnS: document.createElement('span'),
          btnT: document.createTextNode(THIS.pickerCloseText)
        }
        for (var i = 0, segSize = 4; i < jscolor.images.sld[1]; i += segSize) {
          var seg = document.createElement('div')
          seg.style.height = segSize + 'px'
          seg.style.fontSize = '1px'
          seg.style.lineHeight = '0'
          jscolor.picker.sld.appendChild(seg)
        }
        jscolor.picker.sldB.appendChild(jscolor.picker.sld)
        jscolor.picker.box.appendChild(jscolor.picker.sldB)
        jscolor.picker.box.appendChild(jscolor.picker.sldM)
        jscolor.picker.padB.appendChild(jscolor.picker.pad)
        jscolor.picker.box.appendChild(jscolor.picker.padB)
        jscolor.picker.box.appendChild(jscolor.picker.padM)
        jscolor.picker.btnS.appendChild(jscolor.picker.btnT)
        jscolor.picker.btn.appendChild(jscolor.picker.btnS)
        jscolor.picker.box.appendChild(jscolor.picker.btn)
        jscolor.picker.boxB.appendChild(jscolor.picker.box)
      }

      var p = jscolor.picker

      // controls interaction
      p.box.onmouseup = p.box.onmouseout = function() {
        target.focus()
      }
      p.box.onmousedown = function() {
        abortBlur = true
      }
      p.box.onmousemove = function(e) {
        if (holdPad || holdSld) {
          holdPad && setPad(e)
          holdSld && setSld(e)
          if (document.selection) {
            document.selection.empty()
          } else if (window.getSelection) {
            window.getSelection().removeAllRanges()
          }
          dispatchImmediateChange()
        }
      }
      p.padM.onmouseup = p.padM.onmouseout = function() {
        if (holdPad) {
          holdPad = false
          jscolor.fireEvent(valueElement, 'change')
        }
      }
      p.padM.onmousedown = function(e) {
        holdPad = true
        setPad(e)
        dispatchImmediateChange()
      }
      p.sldM.onmouseup = p.sldM.onmouseout = function() {
        if (holdSld) {
          holdSld = false
          jscolor.fireEvent(valueElement, 'change')
        }
      }
      p.sldM.onmousedown = function(e) {
        holdSld = true
        setSld(e)
        dispatchImmediateChange()
      }

      // picker
      var dims = getPickerDims(THIS)
      p.box.style.width = dims[0] + 'px'
      p.box.style.height = dims[1] + 'px'

      // picker border
      p.boxB.style.position = 'absolute'
      p.boxB.style.clear = 'both'
      p.boxB.style.left = x + 'px'
      p.boxB.style.top = y + 'px'
      p.boxB.style.zIndex = THIS.pickerZIndex
      p.boxB.style.border = THIS.pickerBorder + 'px solid'
      p.boxB.style.borderColor = THIS.pickerBorderColor
      p.boxB.style.background = THIS.pickerFaceColor

      // pad image
      p.pad.style.width = jscolor.images.pad[0] + 'px'
      p.pad.style.height = jscolor.images.pad[1] + 'px'

      // pad border
      p.padB.style.position = 'absolute'
      p.padB.style.left = THIS.pickerFace + 'px'
      p.padB.style.top = THIS.pickerFace + 'px'
      p.padB.style.border = THIS.pickerInset + 'px solid'
      p.padB.style.borderColor = THIS.pickerInsetColor

      // pad mouse area
      p.padM.style.position = 'absolute'
      p.padM.style.left = '0'
      p.padM.style.top = '0'
      p.padM.style.width =
        THIS.pickerFace +
        2 * THIS.pickerInset +
        jscolor.images.pad[0] +
        jscolor.images.arrow[0] +
        'px'
      p.padM.style.height = p.box.style.height
      p.padM.style.cursor = 'crosshair'

      // slider image
      p.sld.style.overflow = 'hidden'
      p.sld.style.width = jscolor.images.sld[0] + 'px'
      p.sld.style.height = jscolor.images.sld[1] + 'px'

      // slider border
      p.sldB.style.display = THIS.slider ? 'block' : 'none'
      p.sldB.style.position = 'absolute'
      p.sldB.style.right = THIS.pickerFace + 'px'
      p.sldB.style.top = THIS.pickerFace + 'px'
      p.sldB.style.border = THIS.pickerInset + 'px solid'
      p.sldB.style.borderColor = THIS.pickerInsetColor

      // slider mouse area
      p.sldM.style.display = THIS.slider ? 'block' : 'none'
      p.sldM.style.position = 'absolute'
      p.sldM.style.right = '0'
      p.sldM.style.top = '0'
      p.sldM.style.width =
        jscolor.images.sld[0] +
        jscolor.images.arrow[0] +
        THIS.pickerFace +
        2 * THIS.pickerInset +
        'px'
      p.sldM.style.height = p.box.style.height
      try {
        p.sldM.style.cursor = 'pointer'
      } catch (eOldIE) {
        p.sldM.style.cursor = 'hand'
      }

      // "close" button
      function setBtnBorder() {
        var insetColors = THIS.pickerInsetColor.split(/\s+/)
        var pickerOutsetColor =
          insetColors.length < 2
            ? insetColors[0]
            : insetColors[1] +
              ' ' +
              insetColors[0] +
              ' ' +
              insetColors[0] +
              ' ' +
              insetColors[1]
        p.btn.style.borderColor = pickerOutsetColor
      }
      p.btn.style.display = THIS.pickerClosable ? 'block' : 'none'
      p.btn.style.position = 'absolute'
      p.btn.style.left = THIS.pickerFace + 'px'
      p.btn.style.bottom = THIS.pickerFace + 'px'
      p.btn.style.padding = '0 15px'
      p.btn.style.height = '18px'
      p.btn.style.border = THIS.pickerInset + 'px solid'
      setBtnBorder()
      p.btn.style.color = THIS.pickerButtonColor
      p.btn.style.font = '12px sans-serif'
      p.btn.style.textAlign = 'center'
      try {
        p.btn.style.cursor = 'pointer'
      } catch (eOldIE) {
        p.btn.style.cursor = 'hand'
      }
      p.btn.onmousedown = function() {
        THIS.hidePicker()
      }
      p.btnS.style.lineHeight = p.btn.style.height

      // load images in optimal order
      switch (modeID) {
        case 0:
          var padImg = 'hs.png'
          break
        case 1:
          var padImg = 'hv.png'
          break
      }
      p.padM.style.backgroundImage = "url('" + jscolor.getDir() + "cross.gif')"
      p.padM.style.backgroundRepeat = 'no-repeat'
      p.sldM.style.backgroundImage = "url('" + jscolor.getDir() + "arrow.gif')"
      p.sldM.style.backgroundRepeat = 'no-repeat'
      p.pad.style.backgroundImage = "url('" + jscolor.getDir() + padImg + "')"
      p.pad.style.backgroundRepeat = 'no-repeat'
      p.pad.style.backgroundPosition = '0 0'

      // place pointers
      redrawPad()
      redrawSld()

      jscolor.picker.owner = THIS
      document.getElementsByTagName('body')[0].appendChild(p.boxB)
    }

    function getPickerDims(o) {
      var dims = [
        2 * o.pickerInset +
          2 * o.pickerFace +
          jscolor.images.pad[0] +
          (o.slider
            ? 2 * o.pickerInset +
              2 * jscolor.images.arrow[0] +
              jscolor.images.sld[0]
            : 0),
        o.pickerClosable
          ? 4 * o.pickerInset +
            3 * o.pickerFace +
            jscolor.images.pad[1] +
            o.pickerButtonHeight
          : 2 * o.pickerInset + 2 * o.pickerFace + jscolor.images.pad[1]
      ]
      return dims
    }

    function redrawPad() {
      // redraw the pad pointer
      switch (modeID) {
        case 0:
          var yComponent = 1
          break
        case 1:
          var yComponent = 2
          break
      }
      var x = Math.round((THIS.hsv[0] / 6) * (jscolor.images.pad[0] - 1))
      var y = Math.round(
        (1 - THIS.hsv[yComponent]) * (jscolor.images.pad[1] - 1)
      )
      jscolor.picker.padM.style.backgroundPosition =
        THIS.pickerFace +
        THIS.pickerInset +
        x -
        Math.floor(jscolor.images.cross[0] / 2) +
        'px ' +
        (THIS.pickerFace +
          THIS.pickerInset +
          y -
          Math.floor(jscolor.images.cross[1] / 2)) +
        'px'

      // redraw the slider image
      var seg = jscolor.picker.sld.childNodes

      switch (modeID) {
        case 0:
          var rgb = HSV_RGB(THIS.hsv[0], THIS.hsv[1], 1)
          for (var i = 0; i < seg.length; i += 1) {
            seg[i].style.backgroundColor =
              'rgb(' +
              rgb[0] * (1 - i / seg.length) * 100 +
              '%,' +
              rgb[1] * (1 - i / seg.length) * 100 +
              '%,' +
              rgb[2] * (1 - i / seg.length) * 100 +
              '%)'
          }
          break
        case 1:
          var rgb
          var s
          var c = [THIS.hsv[2], 0, 0]
          var i = Math.floor(THIS.hsv[0])
          var f = i % 2 ? THIS.hsv[0] - i : 1 - (THIS.hsv[0] - i)
          switch (i) {
            case 6:
            case 0:
              rgb = [0, 1, 2]
              break
            case 1:
              rgb = [1, 0, 2]
              break
            case 2:
              rgb = [2, 0, 1]
              break
            case 3:
              rgb = [2, 1, 0]
              break
            case 4:
              rgb = [1, 2, 0]
              break
            case 5:
              rgb = [0, 2, 1]
              break
          }
          for (var i = 0; i < seg.length; i += 1) {
            s = 1 - (1 / (seg.length - 1)) * i
            c[1] = c[0] * (1 - s * f)
            c[2] = c[0] * (1 - s)
            seg[i].style.backgroundColor =
              'rgb(' +
              c[rgb[0]] * 100 +
              '%,' +
              c[rgb[1]] * 100 +
              '%,' +
              c[rgb[2]] * 100 +
              '%)'
          }
          break
      }
    }

    function redrawSld() {
      // redraw the slider pointer
      switch (modeID) {
        case 0:
          var yComponent = 2
          break
        case 1:
          var yComponent = 1
          break
      }
      var y = Math.round(
        (1 - THIS.hsv[yComponent]) * (jscolor.images.sld[1] - 1)
      )
      jscolor.picker.sldM.style.backgroundPosition =
        '0 ' +
        (THIS.pickerFace +
          THIS.pickerInset +
          y -
          Math.floor(jscolor.images.arrow[1] / 2)) +
        'px'
    }

    function isPickerOwner() {
      return jscolor.picker && jscolor.picker.owner === THIS
    }

    function blurTarget() {
      if (valueElement === target) {
        THIS.importColor()
      }
      if (THIS.pickerOnfocus) {
        THIS.hidePicker()
      }
    }

    function blurValue() {
      if (valueElement !== target) {
        THIS.importColor()
      }
    }

    function setPad(e) {
      var mpos = jscolor.getRelMousePos(e)
      var x = mpos.x - THIS.pickerFace - THIS.pickerInset
      var y = mpos.y - THIS.pickerFace - THIS.pickerInset
      switch (modeID) {
        case 0:
          THIS.fromHSV(
            x * (6 / (jscolor.images.pad[0] - 1)),
            1 - y / (jscolor.images.pad[1] - 1),
            null,
            leaveSld
          )
          break
        case 1:
          THIS.fromHSV(
            x * (6 / (jscolor.images.pad[0] - 1)),
            null,
            1 - y / (jscolor.images.pad[1] - 1),
            leaveSld
          )
          break
      }
    }

    function setSld(e) {
      var mpos = jscolor.getRelMousePos(e)
      var y = mpos.y - THIS.pickerFace - THIS.pickerInset
      switch (modeID) {
        case 0:
          THIS.fromHSV(
            null,
            null,
            1 - y / (jscolor.images.sld[1] - 1),
            leavePad
          )
          break
        case 1:
          THIS.fromHSV(
            null,
            1 - y / (jscolor.images.sld[1] - 1),
            null,
            leavePad
          )
          break
      }
    }

    function dispatchImmediateChange() {
      if (THIS.onImmediateChange) {
        if (typeof THIS.onImmediateChange === 'string') {
          eval(THIS.onImmediateChange)
        } else {
          THIS.onImmediateChange(THIS)
        }
      }
    }

    var THIS = this
    var modeID = this.pickerMode.toLowerCase() === 'hvs' ? 1 : 0
    var abortBlur = false
    var valueElement = jscolor.fetchElement(this.valueElement)
    var styleElement = jscolor.fetchElement(this.styleElement)
    var holdPad = false
    var holdSld = false
    var leaveValue = 1 << 0
    var leaveStyle = 1 << 1
    var leavePad = 1 << 2
    var leaveSld = 1 << 3

    // target
    jscolor.addEvent(target, 'focus', function() {
      if (THIS.pickerOnfocus) {
        THIS.showPicker()
      }
    })
    jscolor.addEvent(target, 'blur', function() {
      if (!abortBlur) {
        window.setTimeout(function() {
          abortBlur || blurTarget()
          abortBlur = false
        }, 0)
      } else {
        abortBlur = false
      }
    })

    // valueElement
    if (valueElement) {
      var updateField = function() {
        THIS.fromString(valueElement.value, leaveValue)
        dispatchImmediateChange()
      }
      jscolor.addEvent(valueElement, 'keyup', updateField)
      jscolor.addEvent(valueElement, 'input', updateField)
      jscolor.addEvent(valueElement, 'blur', blurValue)
      valueElement.setAttribute('autocomplete', 'off')
    }

    // styleElement
    if (styleElement) {
      styleElement.jscStyle = {
        backgroundColor: styleElement.style.backgroundColor,
        color: styleElement.style.color
      }
    }

    // require images
    switch (modeID) {
      case 0:
        jscolor.requireImage('hs.png')
        break
      case 1:
        jscolor.requireImage('hv.png')
        break
    }
    jscolor.requireImage('cross.gif')
    jscolor.requireImage('arrow.gif')

    this.importColor()
  }
}

jscolor.install()
