var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('VueCtkDateTimePicker',_vm._b({attrs:{"range":true,"color":"#fb6340","locale":"pt-BR","formatted":_vm.formatted,"format":_vm.format,"button-now-translation":"Agora","no-label":true,"label":_vm.placeholder,"custom-shortcuts":[
    {
      key: 'thisday',
      label: 'Hoje',
      value: 'day'
    },
    {
      key: 'lastday',
      label: 'Ontem',
      value: '-day'
    },
    {
      key: 'thisWeek',
      label: 'Essa semana',
      value: 'isoWeek'
    },
    {
      key: 'lastWeek',
      label: 'Semana passada',
      value: '-isoWeek'
    },
    { key: 'last7Days', label: 'Últimos 7 dias', value: 7 },
    {
      key: 'last30Days',
      label: 'Últimos 30 dias',
      value: 30
    },
    { key: 'thisMonth', label: 'Esse mês', value: 'month' },
    {
      key: 'lastMonth',
      label: 'Mês passado',
      value: '-month'
    }
  ]},on:{"is-hidden":_vm.validate},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'VueCtkDateTimePicker',_vm.props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }