// component: () => import(/* webpackChunkName: "about" */ './views/About.vue')

import { routes as loginRoutes } from '~/app/auth/login'
import { routes as dashboardRoutes } from '~/app/dashboard'
import { routes as profileCompanyRoutes } from '~/app/profile-company'
import { routes as openingHoursRoutes } from '~/app/opening-hours'
import { routes as deliveryAreasRoutes } from '~/app/delivery-areas'
import { routes as MenuRoutes } from '~/app/menu'
import { routes as TypePayments } from '~/app/type-payments'
import { routes as OrdersRoutes } from '~/app/orders'
import { routes as EvaluationsRoutes } from '~/app/evaluations'
import { routes as SettingsRoutes } from '~/app/settings'
import { routes as FinancesRoutes } from '~/app/finances'
import { routes as InvoicesRoutes } from '~/app/invoices'
import { routes as NotificationsRoutes } from '~/app/notifications'
import { routes as neighborhoodsRoutes } from '~/app/neighborhoods'
import { routes as reportsRoutes } from '~/app/reports'
import { routes as radiusRoutes } from '~/app/radius'
import { routes as bankRoutes } from '~/app/bank'
import { routes as financialWithdrawRoutes } from '~/app/financial-withdraw'

const rootRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
]

export default [
  ...rootRoutes,
  ...loginRoutes,
  ...dashboardRoutes,
  ...profileCompanyRoutes,
  ...openingHoursRoutes,
  ...deliveryAreasRoutes,
  ...MenuRoutes,
  ...TypePayments,
  ...OrdersRoutes,
  ...EvaluationsRoutes,
  ...SettingsRoutes,
  ...FinancesRoutes,
  ...InvoicesRoutes,
  ...NotificationsRoutes,
  ...neighborhoodsRoutes,
  ...reportsRoutes,
  ...radiusRoutes,
  ...bankRoutes,
  ...financialWithdrawRoutes,
]
