import Vue from 'vue'
import beforeEach from './before-each'
import Router from 'vue-router'
import routes from '../routes'
import NProgress from 'nprogress'

NProgress.configure({
  easing: 'ease',
  speed: 500,
  zIndex: 9999,
  showSpinner: true,
})

Vue.use(Router)
const router = new Router({
  routes,
  linkActiveClass: 'active',
  // mode: 'history',
  base: process.env.BASE_URL,
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

router.beforeEach(beforeEach)

export default router
