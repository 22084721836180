import http from '~/http'

export const postLogin = user => {
  return http.post('auth/login', user)
}

export const postLogout = () => {
  return http.post('auth/logout')
}

export const postRefreshToken = () => {
  return http.post('auth/refresh')
}

export const postForgotPassword = email => {
  return http.post(
    'auth/password/forgot',
    {
      email,
    },
    {
      baseURL: process.env.VUE_APP_URL_PASSWORD,
    },
  )
}

// eslint-disable-next-line
export const postResetPassword = (token, email, password, password_confirmation) => {
  return http.post(
    'auth/password/reset',
    {
      token,
      email,
      password,
      password_confirmation,
    },
    {
      baseURL: process.env.VUE_APP_URL_PASSWORD,
    },
  )
}
