const OpeningHours = () => import('./components/OpeningHours.vue')

export default [
  {
    path: '/horario-funcionamento',
    name: 'opening.hours',
    meta: {
      title: 'Horário de funcionamento'
    },
    component: OpeningHours
  }
]
