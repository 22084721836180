<script>
import { mapActions, mapGetters } from 'vuex'
import NotificationsWebSocket from '~/components/Notifications'
import { setGlobalTimezone } from '~/shared/utils'
import http from './http'

export default {
  name: 'BoraDelivery',
  components: { NotificationsWebSocket },
  computed: {
    ...mapGetters(['isLogged', 'company']),

    layout() {
      return `${this.$route.meta.layout || 'app'}-layout`
    },
  },

  watch: {
    isLogged(value) {
      if (value) {
        document.body.classList.add('bg-default')
        document.body.classList.remove('bg-default')
        document.body.classList.add('g-sidenav-pinned')
        document.body.classList.add('g-sidenav-show')

        setTimeout(() => {
          const body = window.$('body')
          const sidenavToggler = window.$('.sidenav-toggler')

          window.$('#sidenav-main').hover(
            function() {
              body.addClass('g-sidenav-show')
            },
            function() {
              const hasClassBody = body.hasClass('g-sidenav-pinned g-sidenav-show')
              if (!hasClassBody) {
                body.removeClass('g-sidenav-show')
              }
            },
          )

          sidenavToggler.click(() => {
            const hasClassBody = body.hasClass('g-sidenav-pinned g-sidenav-show')
            if (hasClassBody) {
              sidenavToggler.addClass('active')
              body.removeClass('g-sidenav-pinned')
              body.removeClass('g-sidenav-show')
              body.addClass('g-sidenav-hidden')
              this.saveBodyClassStorage('g-sidenav-hidden')
            } else {
              sidenavToggler.removeClass('active')
              body.addClass('g-sidenav-pinned')
              body.addClass('g-sidenav-show')
              body.removeClass('g-sidenav-hidden')
              this.saveBodyClassStorage('g-sidenav-pinned g-sidenav-show')
            }
          })
        }, 1000)
      }
    },

    company() {
      setGlobalTimezone(this.company.timezone)
    },
  },

  methods: {
    ...mapActions(['updateDataAuth', 'addDeliverymen']),

    saveBodyClassStorage(className) {
      localStorage.setItem('menu-pinned', JSON.stringify(className))
    },
  },

  mounted() {
    document.querySelector('.splash-screen-app').classList.add('d-none')

    if (this.isLogged) {
      http
        .get('auth/account')
        .then(({ data }) => {
          this.updateDataAuth(data.data)
          setGlobalTimezone(this.company.timezone)
          this.addDeliverymen(data.data.settings.deliverymen_online)
        })
        .catch(() => {
          this.$alert.show('Ops', 'Houve um erro ao carregar suas informações. Tente novamente.', 'error')
        })
    }
  },
}
</script>

<template>
  <div id="app">
    <NotificationsWebSocket v-if="isLogged && company.id" />
    <!-- <perfect-scrollbar> -->
    <component :is="layout">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view />
      </slide-y-up-transition>
    </component>
    <!-- </perfect-scrollbar> -->
  </div>
</template>

<style scoped>
.ps {
  height: 100vh;
}
</style>
