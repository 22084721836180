const Index = () => import('./components/index')

export default [
  {
    path: '/transferir-saldo',
    name: 'financial-withdraw',
    meta: {
      title: 'Transferir Saldo',
    },
    component: Index,
  },
]
