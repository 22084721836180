const Index = () => import('./components/index')

export default [
  {
    path: '/relatorios',
    name: 'reports',
    meta: {
      title: 'Relatórios',
    },
    component: Index,
  },
]
