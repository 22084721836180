import store from '../store'
import { localStorageGetItem } from '../shared/local-storage'
import checkPermission from '../shared/acl/check'

const isAuthRoute = route => route.path.indexOf('/login') !== -1
const isAuthPassword = route => route.path.indexOf('/recuperar-senha') !== -1
const isRouteSetCompany = route => route.path.indexOf('/selecionar-empresa') !== -1
const isLogged = () => store.getters.isLogged

export default async (to, from, next) => {
  const title =
    to.meta.title === undefined
      ? process.env.VUE_APP_NAME
      : process.env.VUE_APP_NAME + ' - ' + (to.meta.title_sub === undefined ? to.meta.title : to.meta.title_sub)
  document.title = title

  if (
    localStorageGetItem('token') !== null &&
    localStorageGetItem('token') !== '' &&
    localStorageGetItem('token') !== undefined
  ) {
    if (!store.getters.isLogged) {
      await store.dispatch('setDataFromLocalStorage')
    }

    if (store.getters.isLogged && !store.getters.isSigning) {
      const permissions = store.getters.permissions
      if (to.meta.shield === null || to.meta.shield === undefined || to.meta.shield === '') {
        next()
      } else {
        if (checkPermission(to.meta.shield, permissions)) {
          next()
        } else {
          if (from.path === '/') {
            next({
              name: 'dashboard',
            })
          }
        }
      }
    }

    if (store.getters.isSigning && !isRouteSetCompany(to)) {
      next({
        name: 'set.company',
      })
    } else {
      next()
    }

    if ((isAuthRoute(to) && isLogged()) || (isAuthPassword(to) && isLogged())) {
      next({
        name: 'dashboard',
      })
    }
  } else {
    if (!isAuthRoute(to) && !isLogged() && !isAuthPassword(to)) {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      })
    } else {
      next()
    }
  }
}
