const Profile = () => import('./components/Profile.vue')

export default [
  {
    path: '/perfil',
    name: 'profile.company',
    meta: {
      title: 'Perfil da empresa'
    },
    component: Profile
  }
]
