<script>
import { formatDate } from '~/shared/utils'

export default {
  name: 'Timeago',

  props: {
    datetime: { type: String },
    refresh: { default: false },
    tooltip: { default: false }
  },

  data() {
    return {
      interval: null,
      date: ''
    }
  },

  methods: {
    dateFormatted() {
      this.date = formatDate(this.datetime, 'distanceNow')
    }
  },

  watch: {
    datetime() {
      this.dateFormatted()
    }
  },

  mounted() {
    if (this.refresh) {
      this.interval = setInterval(() => {
        this.dateFormatted()
      }, this.refresh)
    }
  },

  destroyed() {
    clearInterval(this.interval)
  }
}
</script>

<template>
  <span v-tooltip="tooltip ? $options.filters.date(datetime) : ''">
    {{
    date
    }}
  </span>
</template>
