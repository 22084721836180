<script>
import { Howl } from 'howler'
import { mapActions, mapGetters } from 'vuex'
import { apiIndex as apiIndexOrders } from '~/app/orders/services'

export default {
  name: 'OrdersNotifications',

  data() {
    return {
      documentTitle: document.title,
      connected: false,
      interval: null,
      firstLoad: false,
      sound: null,
      soundShutdown: null,
      soundSilence: null,
    }
  },

  computed: {
    ...mapGetters(['company', 'isLogged', 'playSoundBell']),
  },

  watch: {
    company() {
      if (this.firstLoad) {
        this.loadOrders()
      }
      this.firstLoad = true
    },

    playSoundBell(play) {
      if (play) {
        const count = this.$store.getters.getCountTotalOpenedOrders

        setTimeout(() => {
          this.sound?.play()
          document.title = `(${count}) ${this.documentTitle}`
        }, 500)

        if (navigator.setAppBadge) {
          navigator.setAppBadge(count)
        }
      } else {
        this.sound?.pause()
        this.sound?.seek(0)
        document.title = `${this.documentTitle}`
        if (navigator.clearAppBadge) {
          navigator.clearAppBadge()
        }
      }
    },
  },

  methods: {
    ...mapActions(['addOrder', 'setCompany', 'updateOrder', 'setOrders', 'setOrdersPendents']),

    loadOrders() {
      apiIndexOrders()
        .then(({ data }) => {
          this.setOrders(data.data.orders)
          this.setOrdersPendents(data.data.pendents)
        })
        .catch(() => {
          this.$alert.show('Oops', 'Houve um erro ao carregar os pedidos, tente novamente.', 'warning')
        })
    },

    titleCount(count) {
      const pattern = /^\(\d+\)/

      if (count === 0 || pattern.test(document.title)) {
        document.title = document.title.replace(pattern, count === 0 ? '' : `(${count})`)
      } else {
        document.title = `(${count}) ${document.title}`
      }
    },

    notifyDesktop(data) {
      if (Notification.permission === 'granted') {
        const notification = new Notification(`Novo pedido ${data.order.number}!`, {
          icon: '/images/logo.png',
          body: 'Novo pedido realizado no Bora Delivery, clique aqui para ver.',
          requireInteraction: true,
        })
        notification.onclick = function(e) {
          e.preventDefault()
          window.focus()
          window.location = `/#/pedidos/${data.order.id}`
          notification.close()
        }
      }
    },

    listen() {
      window.Echo.private(`Company.${this.company.id}`)
        .listen('.order_still_opened', data => {
          this.$alert.show(
            'Pedido ainda não aceito!',
            `Pedido #${data.order.number} ainda não foi aceito por você.
            <a href="/#/pedidos/${data.order.id}">[ver]</a>`,
            'show',
            'ico-success',
            null,
            30000,
          )
          this.addOrder(data.order)
          this.notifyDesktop(data)
        })
        .listen('.order_created', data => {
          this.$alert.show(
            'Novo pedido!',
            `Pedido #${data.order.number} realizado agora!
            <a href="/#/pedidos/${data.order.id}">[ver]</a>`,
            'show',
            'ico-success',
            null,
            30000,
          )
          this.addOrder(data.order)
          this.notifyDesktop(data)
        })
        .listen('.order_status_changed', data => {
          this.updateOrder({
            id: data.order.id,
            payload: data.order,
          })
        })
        .listen('.order_canceled_by_user', data => {
          this.$alert.show(
            `Pedido #${data.order.number} cancelado pelo usuário!`,
            `Motivo: (${data.order.reason_canceled})
            <a href="/#/pedidos/${data.order.id}">[ver]</a>`,
            'show',
            'ico-warning',
            null,
            30000,
          )
          this.updateOrder({
            id: data.order.id,
            payload: data.order,
          })
        })
        .listen('.order_canceled_by_system', data => {
          this.$alert.show(
            `Pedido #${data.order.number} cancelado pelo sistema, sua empresa foi colocada como offline!`,
            `Motivo: (${data.order.reason_canceled})
            <a href="/#/pedidos/${data.order.id}">[ver]</a>`,
            'show',
            'ico-warning',
            null,
            30000,
          )
          this.updateOrder({
            id: data.order.id,
            payload: data.order,
          })
        })
        .listen('.order_deliveryman_accepted', data => {
          this.$alert.show(
            'Pedido atualizado!',
            `Pedido #${data.order.number} atribuído ao entregador ${data.deliveryman.name}.`,
            'show',
            'ico-success',
            null,
            30000,
          )
          this.updateOrder({
            id: data.order.id,
            payload: {
              ...data.order,
              deliveryman: {
                user: data.deliveryman,
              },
            },
          })
        })
        .listen('.company_toggle_online', ({ online }) => {
          if (online) {
            this.$alert.show(
              'Status alterado',
              `Empresa foi aberta em outro local.`,
              'show',
              'ico-success',
              null,
              30000,
            )
          }

          if (!online) {
            this.$alert.show(
              'Status alterado',
              `Empresa fechada automaticamente como programado.`,
              'show',
              'ico-success',
              null,
              30000,
            )
          }

          this.soundShutdown?.play()

          this.setCompany({ ...this.company, online })
        })
        .listen('.invoice_received', ({ id, number }) => {
          this.$alert.show('Recebemos seu pagamento!', `Fatura #${number} recebida com sucesso!`, 'show', 'ico-success')
        })
    },

    tryConnect() {
      if (this.isLogged && this.company.id && window.Pusher.isReady) {
        this.listen()
        return true
      }
      return false
    },
  },

  mounted() {
    if (this.company.id) {
      this.loadOrders()
    } else {
      this.firstLoad = true
    }

    this.interval = setInterval(() => {
      if (this.tryConnect()) {
        clearInterval(this.interval)
      }
    }, 2000)

    this.sound = new Howl({
      src: ['/sounds/bell.mp3'],
      volume: 1,
      autoplay: false,
      loop: true,
    })

    this.soundShutdown = new Howl({
      src: ['/sounds/shutdown.mp3'],
      volume: 1,
    })

    this.soundSilence = new Howl({
      src: ['/sounds/silence.mp3'],
      volume: 1,
      loop: true,
    }).play()
  },

  destroyed() {
    clearInterval(this.interval)
  },
}
</script>

<template>
  <span></span>
</template>

<style scoped>
.audio-not-show {
  display: none;
}
</style>
