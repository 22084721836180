const Profile = () => import('./components/TypePayments.vue')

export default [
  {
    path: '/tipos-de-pagamento',
    name: 'type.payments',
    meta: {
      title: 'Tipos de pagamento'
    },
    component: Profile
  }
]
