export default {
  user: {},
  token: '',
  permissions: [],
  companies: [],
  company: {},
  settings: {
    delivery_app: {},
  },
}
