<script>
import { mapActions, mapGetters } from 'vuex'
import { postLogout } from '~/app/auth/login/services'

export default {
  name: 'MenuUser',

  computed: {
    ...mapGetters(['company']),

    phone() {
      return this.company?.city?.whatsapp_support
    },
  },

  methods: {
    ...mapActions(['logout']),

    sendLogout() {
      postLogout().finally(() => {
        this.logout().then(() => {
          this.$router.push({ name: 'login' })
        })
      })
    },
  },
}
</script>

<template>
  <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
    <router-link :to="{ name: 'profile.company' }" class="dropdown-item">
      <i class="ni ni-single-02"></i>
      <span>Perfil da empresa</span>
    </router-link>
    <router-link :to="{ name: 'settings', query: { tab: 'printer' } }" class="dropdown-item">
      <i class="ni ni-settings-gear-65"></i>
      <span>Configurações de impressão</span>
    </router-link>
    <a target="_blank" :href="`https://wa.me/55${phone}`" class="dropdown-item" v-if="!!phone">
      <i class="ni ni-support-16"></i>
      <span>Suporte</span>
    </a>
    <div class="dropdown-divider"></div>
    <a href="#" @click.prevent="sendLogout" class="dropdown-item">
      <i class="ni ni-user-run"></i>
      <span>Sair</span>
    </a>
  </div>
</template>
