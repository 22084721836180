<script>
import { mapGetters } from 'vuex'
import ModalCompanySelector from '~/components/Modal/ModalCompanySelector'
import { modalComponent } from '~/shared/utils'

export default {
  name: 'CompanySelector',
  data() {
    return {
      isMobile: window.isMobile(),
    }
  },

  computed: {
    ...mapGetters(['company', 'totalCompanies']),
  },

  methods: {
    showModal() {
      modalComponent(this, {
        component: ModalCompanySelector,
        styles: {
          height: '75%',
        },
      })
    },
  },
}
</script>

<template>
  <div class="containerCompanySelector mb-0 text-sm font-weight-bold" v-if="!!company.name">
    <img :src="company.logo ? company.logo : '/images/no-avatar.jpg'" class="rounded-circle" />
    <span>
      <span v-if="!isMobile">
        {{ company.name }}
        <small>
          <small>- {{ company.cpf_cnpj | cpf_cnpj }}</small>
        </small>
        <br />
      </span>
      <small v-if="totalCompanies > 1">
        <a href @click.prevent="showModal">
          <span v-if="!isMobile">Alterar &nbsp;</span>
          <ArrowLeftRightIcon :size="18" />
        </a>
      </small>
    </span>
  </div>
</template>

<style scoped>
.containerCompanySelector {
  font-size: 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.containerCompanySelector span {
  color: #fff;
}
img.rounded-circle {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
a {
  color: #fff;
  transition: all 0.3s;
}
a:hover {
  color: rgba(255, 255, 255, 0.65);
}
.material-design-icon__svg {
  margin-top: -2px;
}
</style>
