import * as types from './mutations-types'

export default {
  [types.SET_QZ_CONNECTED](state, status) {
    state.printer.qzConnected = status
  },

  [types.ADD_DELIVERYMEN](state, payload) {
    if (payload !== null) {
      state.deliverymenOnline = payload
    } else {
      state.deliverymenOnline++
    }
  },

  [types.REMOVE_DELIVERYMEN](state) {
    if (state.deliverymenOnline > 0) {
      state.deliverymenOnline--
    }
  },
}
