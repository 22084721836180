<script>
export default {
  name: 'Button',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
    message: {
      type: String,
      default: null,
    },
  },
  methods: {
    callback(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<template>
  <button :type="type" @click="callback($event)" :disabled="isLoading" class="btn">
    <slot v-if="!isLoading"></slot>
    <img v-if="isLoading" height="20" src="/images/loading.svg" alt="Carregando..." />
    <span v-if="isLoading && message">&nbsp; {{ message }}</span>
  </button>
</template>
