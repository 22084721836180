<script>
import { mapGetters, mapActions } from 'vuex'
import BoraModal from '../Modal'

export default {
  name: 'ModalCompanySelector',

  components: { BoraModal },

  props: {
    showClose: { type: Boolean, default: true },
  },

  data() {
    return {
      companySelected: {},
    }
  },

  computed: {
    ...mapGetters(['companies', 'company']),
  },

  methods: {
    ...mapActions(['setCompany']),

    customLabel({ name, cpf_cnpj: cpfCnpj }) {
      if (name) {
        return `${name} - ${this.$options.filters.cpf_cnpj(cpfCnpj)}`
      }
      return ''
    },

    select() {
      if (!this.companySelected) {
        this.$alert.show('Oops', 'Selecione uma empresa.', 'error')
        return
      }
      this.setCompany(this.companySelected)
      this.$emit('close')
      if (this.$route.name === 'set.company') {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'dashboard' })
        }
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<template>
  <bora-modal title="Selecionar empresa" :showClose="showClose">
    <div class="modal-body">
      <div class="small col-md-12 p-0">Selecione uma empresa para continuar</div>
      <br />
      <span v-if="!companies.length">
        <bora-loading :loading="true" type="grow" />
      </span>
      <bora-select
        v-if="companies.length"
        v-model="companySelected"
        :internalSearch="true"
        labelSelect="name"
        class="col-md-12 p-0"
        placeholder="Selecione a empresa"
        :options="companies"
        :selected="company"
        :custom-label="customLabel"
        :searchable="true"
        autocomplete="off"
        :showNoResults="false"
      />
    </div>
    <div class="modal-footer">
      <bora-button @click="select" type="button" class="btn btn-primary">Selecionar</bora-button>
    </div>
  </bora-modal>
</template>

<style scoped>
.modal-open {
  overflow: auto;
  max-height: 600px;
}
</style>
